import './Footer.css';

function Footer({ pageTitle }) {
  return (
    <div className="App-footer text-center text-dark my-4">
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <p>Penguins embrace the power of individual strength, family commitment and <br />
              community support to successfully care for their family in the harshest of conditions.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;