import React from 'react';
import './Attorney.css';
const Attorney = () => {

  return (
    <div className="tab-pane fade" id="nav-attorney" role="tabpanel" aria-labelledby="nav-attorney-tab">
    <div className='rightSec p-md-5 p-3'>
      <div className='row margin_50px'>
        <div className='col-md-3 text-white'>
          <img src='./images/elder-law.jpg' className='w-100 mb-3' alt='' />
          <div className='col-md-12'>
            <h6 className='text-white fs-5'>Bar Admissions</h6>
            <ul className='w-auto d-grid gap-2 ps-0 list-none fw-light'>
              <li><img className='me-2' src='./images/flag-1.png' alt='' /> Massachusetts </li>
              <li><img className='me-2' src='./images/flag-2.png' alt='' /> North Carolina</li>
            </ul>
            <img className='mt-4' src='./images/flag-3.png' width={150} alt='' />
          </div>
        </div>
        <div className='col-md text-white ps-md-5'>
          <p className='textAlign'>Patricia M. Shumaker practices primarily in the areas of complex estate planning,
            trust and estate administration, elder law, and long-term care planning.
            Ms. Shumaker provides comprehensive planning for the costs of long-term care
            including the application and appeal for Medicaid benefits. Ms. Shumaker is a cum laude
            graduate of the University of South Carolina School of Law where she was the articles editor
            for the ABA Real Property, Trust & Estate Journal and was named to the Order of the Coif.
            She is an undergraduate of the University of Massachusetts in Amherst.</p>         
          
          <div className='col-md-12'>
            <h6 className='text-white fs-23'><img className='me-2' src='./images/education.png' alt='' /> Education</h6>
            <ul className='fs-20 ps-5 ms-3'>
              <li>University of South Carolina School of Law, Columbia, South Carolina, J.D. <br /> cum laude </li>
              <li>Law Journal: ABA Real Property, Trust & Estate Journal, Articles Editor</li>
              <li>Moot Court Bar, Competition Team Member</li>
              <li>President of Women in Law Organization</li>
              <li>University of Massachusetts, Amherst, Massachusetts</li>
            </ul>
          </div>
          <div className='col-md-12'>
            <h6 className='text-white fs-23'><img className='me-2' src='./images/award.png' alt='' /> Honors and Awards</h6>
            <ul className='fs-20 ps-5 ms-3'>
              <li>Cali Award in Professional Responsibility , 1999 – 2000</li>
              <li>Karen Lee Scholarship Award, 1998 – 1999</li>
              <li>Order of the Coif</li>
            </ul>
          </div>
          <div className='col-md-12'>
            <h6 className='text-white fs-23'><img className='me-2' src='./images/professional.png' alt='' /> Professional Associations and Membership</h6>
            <ul className='fs-20 ps-5 ms-3'>
              <li>Massachusetts Bar Association (MBA), Member</li>
              <li>National Academy of Elder Law Attorneys (NAELA) and MA Chapter (MANAELA), Member</li>
              <li>Boston Estate Planning Council (BEPC), Member</li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
  );

};

export default Attorney;
