import React, { useEffect, useState } from 'react';
import './EditPlan.css';
import { useParams } from 'react-router-dom';
import { getPlanById, updatePlanById, getPlanParent } from '../../../services/planListService';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MessageDialog from '../../../components/Modal/MessageDialog';
import Modal from 'react-modal';
import Constants from '../../../utilities/constants';
import SucessValidate from '../../../components/Modal/SucessValidate';
const EditPlan = () => {
  const baseURL = Constants.BASE_URL;
  const { id } = useParams();
  const navigate = useNavigate();

  const [plan, setPlan] = useState({
    contactStatus: 'Charity',
  });

  const [subRelationshipOptions, setSubRelationshipOptions] = useState([]);
  const [showParentDropdown, setShowParentDropdown] = useState(false);
  const [fullNames, setFirstNames] = useState([]);
  const [planName, setPlanName] = useState(null);
  const [zipResponseSuccess, setZipResponseSuccess] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isError, setIsError] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);

  const [isCharityVisible, setIsCharityVisible] = useState(true);
  const [isContactInformationVisible, setContactInformationVisible] = useState(true);
  const [isAddressVisible, setAddressVisible] = useState(true);
  const [isRelationshipVisible, setRelationshipVisible] = useState(true);
  const [isMaritalStatusVisible, setMaritalStatusVisible] = useState(true);

  const [isDeceasedDeathVisible, setDeceasedDeathVisible] = useState(false);
  const [isEstrangedDeathVisible, setEstrangedDeathVisible] = useState(false);
  const [isRemoveDeathVisible, setRemoveDeathVisible] = useState(false);

  const contactStatusOptions = [
    { value: "", label: "Select Status" },
    { value: "Adult", label: "Active (Adult)" },
    { value: "Minor Child", label: "Active (Minor Child)" },
    { value: "Incapacitated", label: "Incapacitated" },
    { value: "Disabled", label: "Disabled" },
    { value: "Charity", label: "Charity" },
    { value: "Deceased", label: "Deceased" },
    { value: "Estranged", label: "Estranged" },
    { value: "Remove", label: "Remove" }
  ];

  let visibleOptions = [];
  if (plan.contactStatus === "Adult") {
    visibleOptions = ["Adult", "Minor Child", "Incapacitated", "Disabled", "Charity", "Deceased", "Estranged", "Remove"  ];
  } else if (plan.contactStatus === "Minor Child") {
    visibleOptions = ["Adult", "Minor Child", "Incapacitated", "Disabled", "Deceased", "Charity", "Estranged", "Remove"  ];
  } else if (plan.contactStatus === "Incapacitated") {
    visibleOptions = ["Adult", "Minor Child", "Incapacitated", "Disabled", "Deceased", "Charity", "Estranged", "Remove"  ];
  } else if (plan.contactStatus === "Disabled") {
    visibleOptions = ["Adult", "Minor Child", "Incapacitated", "Disabled", "Deceased", "Charity", "Estranged", "Remove"  ];
  } else if (plan.contactStatus === "Deceased") {
    visibleOptions = ["Adult", "Minor Child", "Incapacitated", "Disabled", "Deceased", "Charity", "Estranged", "Remove"  ];
  } else if (plan.contactStatus === "Estranged") {
    visibleOptions = ["Adult", "Minor Child", "Incapacitated", "Disabled", "Deceased", "Charity", "Estranged", "Remove"  ];
  }else if (plan.contactStatus === "Remove") {
    visibleOptions = ["Adult", "Minor Child", "Incapacitated", "Disabled", "Deceased", "Charity", "Estranged", "Remove"  ];
  }else if (plan.contactStatus === "Charity") {
    visibleOptions = ["Adult", "Minor Child", "Incapacitated", "Disabled", "Deceased", "Charity", "Estranged", "Remove"  ];
  }
     else {
    visibleOptions = [plan.contactStatus];
  }

  const handleRelationshipChange = async (event) => {
    const selectedRelationship = event.target.value;
    // alert(selectedRelationship)

    // setPlan(prevData => ({
    //   ...prevData,
    //   "Relationship Type": selectedRelationship
    // }));

    // fetchPlanParentData( planName, selectedRelationship)
    try {
      const data = await getPlanParent(planName, selectedRelationship);
      // console.log(data, "fullNamesfullNamesfullNamesfullNamesfullNamesfullNamesfullNamesfullNamesfullNamesfullNames")
      const fullNames = data.map(item => `${item["First Name"]} ${item["Last Name"]}`);
      setFirstNames(fullNames);

    } catch (error) {
      console.error('Error fetching plan details:', error);
    }

    setPlan(prevData => ({
      ...prevData,
      "Relationship Type": selectedRelationship,
      // Clear the parent value if the selected relationship type doesn't allow for selecting a parent
      "Parent": ['Child', 'Grandchild', 'Niece/Nephew', 'Primary Contact'].includes(selectedRelationship) ? prevData["Parent"] : ""
    }));

    let options = [];


    if (selectedRelationship === 'Parent') {
      setShowParentDropdown(false);
      options = ['father', 'mother'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    } else if (selectedRelationship === 'Spouse') {
      setShowParentDropdown(false);
      options = ['husband', 'wife', 'ex-husband', 'ex-wife', 'deceased-husband', 'deceased-wife', 'deceased spouse'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    } else if (selectedRelationship === 'Child') {
      setShowParentDropdown(true);
      options = ['daughter', 'son', 'deceased child', 'estranged child', 'adopted child', 'stepchild'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Grandchild') {
      setShowParentDropdown(true);
      options = ['grandson', 'granddaughter'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Sibling') {
      setShowParentDropdown(false);
      options = ['brother', 'sister', 'step brother', 'step sister', 'brother-in law', 'sister-in law'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Niece/Nephew') {
      setShowParentDropdown(true);
      options = ['niece', 'nephew'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Cousin') {
      setShowParentDropdown(false);
      options = ['cousin'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Friend') {
      setShowParentDropdown(false);
      options = ['friend'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Professionals') {
      setShowParentDropdown(false);
      options = ['accountant', 'financial advisor', 'attorney'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Primary Contact') {
      setShowParentDropdown(false);
      options = ['Husband', 'Wife', 'Single Client'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }

    setSubRelationshipOptions(options);


  };

  const handleParentChange = (e) => {
    const { name, value } = e.target;

    setPlan(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

useEffect(() => {
  const fetchData = async () => {
    const data = await getPlanById(id);
    setSubRelationshipOptions(data && data["Relationship Order"])
    handleRelationshipChange({ target: { value: data["Relationship Type"] } }, true);
    handleParentChange({ target: { value: data["Parent"] } }, true)
  };

  fetchData();
}, []);

  // const handleContactStatusChange = (e) => {
  //   const { name, value } = e.target;
  //   // alert(value)
  //   setPlan(prevData => ({
  //     ...prevData,
  //     [name]: value
  //   }));
  // };


  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchPlanById = async () => {
      try {
        const data = await getPlanById(id);
        setPlan(data);
        setSubRelationshipOptions(data && data["Relationship Order"])
        setPlanName(data && data["Plan Name"])

        if (data && data["Zipcode"] && /^\d{5}$/.test(data["Zipcode"])) {
          setZipResponseSuccess(true);
        } else {
          setZipResponseSuccess(false);
        }

        // console.log(subRelationshipOptions, "datadata")
      } catch (error) {
        console.error('Error fetching plan details:', error);
      }
    };

    fetchPlanById();
  }, [id]);

  

  const requiredFieldsMapping = {
    
    "Adult": ["First Name", "Last Name", "Relationship Type", "Relationship Order", "Mobile", "Email", "YOB", "US Citizen", "Marital Status", "Address 1", "City", "State", "Zipcode"],

    "Minor Child": ["First Name", "Last Name", "Relationship Type", "Relationship Order", "YOB", "US Citizen", "Address 1", "City", "State", "Zipcode"],

    "Incapacitated": ["First Name", "Last Name", "Relationship Type", "Relationship Order", "YOB","US Citizen", "Marital Status", "Address 1", "City", "State", "Zipcode"],

    "Disabled": ["First Name", "Last Name", "Relationship Type", "Relationship Order", "YOB", "US Citizen", "Marital Status", "Address 1", "City", "State", "Zipcode"],

    "Charity": ["Charity Name", "Mobile", "Email", "Address 1", "City", "State", "Zipcode"],

    "Deceased": ["First Name", "Last Name",  "Date of Death", "Place of Death", "Residency of Death"],

    "Estranged": ["First Name", "Last Name", "Date of Estrangement"],

    "Remove": ["First Name", "Last Name", "Date of Remove"]
  };

  const validateFields = (planToValidate) => {
        const contactStatus = planToValidate["contactStatus"];
        const requiredFields = requiredFieldsMapping[contactStatus] || [];

        for (const field of requiredFields) {
            if (!planToValidate[field]) {
                setDialogTitle('Error');
                setIsError(true);
                setDialogMessage(`${field.replace('_', ' ')} is required.`);
                setIsDialogVisible(true);
                return false;
            }
        }
        return true;
    };

  const updatePlan = async (id) => {
    try {
      // let allFieldsValid = true; 

      // setPlan(prevPlan => {
      //     const contactStatus = prevPlan["contactStatus"];
      //     const requiredFields = requiredFieldsMapping[contactStatus] || [];

      //     for (const field of requiredFields) {
      //         if (!prevPlan[field]) {
      //             allFieldsValid = false;

      //             setDialogTitle('Error');
      //             setIsError(true);
      //             setDialogMessage(`${field.replace('_', ' ')} is required.`);
      //             setIsDialogVisible(true);
      //             break;
      //         }
      //     }

      //     return prevPlan;
      // });

    const isValid = validateFields(plan);
      if (isValid) {
      if (zipResponseSuccess) {
        const isResult = await updatePlanById(id, plan);
        // console.log(isResult, "isResult")

        if (isResult.success) {
          if(isResult.isVerified){
            setErrorMessage('You have successfully updated your contacts. Within the next few days, you will receive a letter by DocuSign to authorize me to bring your records to Conn Kavanaugh LLP. Once completed, we can continue to work together and I can provide you with improved service and a full range of legal expertise.');
            setIsSuccessVisible(true);
            setIsDialogVisible(false);
          }
          else{
            setDialogTitle('Success');
            setIsError(false); 
            setDialogMessage('Plan updated successfully');
            setIsDialogVisible(true);
            setIsSuccessVisible(false);
          } 
        }
      } else {
        setDialogTitle('Error');
        setIsError(true);
        setDialogMessage('Invalid ZIP code or ZIP code not found.');
        setIsDialogVisible(true);
        return false;
      }
  }
    } catch (error) {
      console.error('Error updating plan:', error);
    }
  };

  const fieldsToClear = [
    "First Name", "Last Name", "M", "Email", "Nickname", "Relationship Type",
    "Relationship Order", "Parent", "Mobile", "YOB", "US Citizen",
    "Marital Status", "Address 1", "City", "Another Name Value", "State",
    "Zipcode", "Charity Name"
  ];

  const fetchPlanParentData = async (planName, selectedRelationship) => {
    try {
      const data = await getPlanParent(planName, selectedRelationship);
      const fullNames = data.map(item => `${item['First Name']} ${item['Last Name']}`);
      setFirstNames(fullNames);
    } catch (error) {
      console.error('Error fetching plan details:', error);
    }
  };

  useEffect(() => {
      fetchPlanParentData(plan["Plan Name"], plan["Relationship Type"])

      if (plan && (plan.contactStatus === "Adult")) {
      setIsCharityVisible(true);
      setContactInformationVisible(true)
      setAddressVisible(true)
      setRelationshipVisible(true)
      setMaritalStatusVisible(true)

      setRemoveDeathVisible(false)
      setEstrangedDeathVisible(false)
      setDeceasedDeathVisible(false)
    }
     else if (plan && (plan.contactStatus === "Minor Child")) {
      setIsCharityVisible(true);
      setRelationshipVisible(true)
      setContactInformationVisible(false)
      setAddressVisible(true)

      setMaritalStatusVisible(false)

      setRemoveDeathVisible(false)
      setEstrangedDeathVisible(false)
      setDeceasedDeathVisible(false)
    }
    else if (plan && (plan.contactStatus === "Incapacitated")) {
      setContactInformationVisible(false)
      setRelationshipVisible(true)
      setAddressVisible(true)
      setIsCharityVisible(true);
      setMaritalStatusVisible(true)

      setRemoveDeathVisible(false)
      setEstrangedDeathVisible(false)
      setDeceasedDeathVisible(false)
    }
    else if (plan && (plan.contactStatus === "Disabled")) {
      setContactInformationVisible(false)
      setRelationshipVisible(true)
      setIsCharityVisible(true);
      setAddressVisible(true)
      setMaritalStatusVisible(true)

      setRemoveDeathVisible(false)
      setEstrangedDeathVisible(false)
      setDeceasedDeathVisible(false)
    }
      else if (plan && (plan.contactStatus === "Deceased")) {
      setIsCharityVisible(true);
      setContactInformationVisible(false)
      setAddressVisible(false)
      setRelationshipVisible(false)

      setDeceasedDeathVisible(true)
      setRemoveDeathVisible(false)
      setEstrangedDeathVisible(false)

    }
      else if (plan && (plan.contactStatus === "Charity")) {
      setIsCharityVisible(false);
      setContactInformationVisible(true)
      setAddressVisible(true)
      setRelationshipVisible(false)

      setRemoveDeathVisible(false)
      setEstrangedDeathVisible(false)
      setDeceasedDeathVisible(false)
    }
      else if (plan && (plan.contactStatus === "Estranged")) {
      setIsCharityVisible(true);
      setContactInformationVisible(false)
      setAddressVisible(false)
      setRelationshipVisible(false)

      setEstrangedDeathVisible(true)
      setRemoveDeathVisible(false)
      setDeceasedDeathVisible(false)
    }
      else if (plan && (plan.contactStatus === "Remove")) {
      setIsCharityVisible(true);
      setContactInformationVisible(false)
      setAddressVisible(false)
      setRelationshipVisible(false)

      setRemoveDeathVisible(true)
      setEstrangedDeathVisible(false)
      setDeceasedDeathVisible(false)

    }
    else {
      // setIsFirstMiddelLastSuffixKnownNickVisible(true);
    }

  }, [plan]);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    // alert(name)
    // alert(value)

    if (name === "contactStatus") {

      const updatedFormData = { ...setPlan };

      // Clear only the relevant fields
      fieldsToClear.forEach(field => {
        updatedFormData[field] = "";
      });

      // Update formData with cleared fields and the new contactStatus value
      updatedFormData[name] = value;
      setPlanName(updatedFormData);

      if (value === "Adult") {
        setIsCharityVisible(true);
        setContactInformationVisible(true)
        setAddressVisible(true)
        setRelationshipVisible(true)
        setMaritalStatusVisible(true)

        setRemoveDeathVisible(false)
        setEstrangedDeathVisible(false)
        setDeceasedDeathVisible(false)
      }
      else if (value === "Minor Child") {
        setIsCharityVisible(true);
        setRelationshipVisible(true)
        setContactInformationVisible(false)
        setAddressVisible(true)

        setMaritalStatusVisible(false)

        setRemoveDeathVisible(false)
        setEstrangedDeathVisible(false)
        setDeceasedDeathVisible(false)
      }
      else if (value === "Incapacitated") {
        setContactInformationVisible(false)
        setRelationshipVisible(true)
        setAddressVisible(true)
        setIsCharityVisible(true);
        setMaritalStatusVisible(true)

        setRemoveDeathVisible(false)
        setEstrangedDeathVisible(false)
        setDeceasedDeathVisible(false)
      }
      else if (value === "Disabled") {
        setContactInformationVisible(false)
        setRelationshipVisible(true)
        setIsCharityVisible(true);
        setAddressVisible(true)
        setMaritalStatusVisible(true)

        setRemoveDeathVisible(false)
        setEstrangedDeathVisible(false)
        setDeceasedDeathVisible(false)
      }
      else if (value === "Deceased") {
        setIsCharityVisible(true);
        setContactInformationVisible(false)
        setAddressVisible(false)
        setRelationshipVisible(false)

        setDeceasedDeathVisible(true)
        setRemoveDeathVisible(false)
        setEstrangedDeathVisible(false)

      }
      else if (value === "Charity") {
        setIsCharityVisible(false);
        setContactInformationVisible(true)
        setAddressVisible(true)
        setRelationshipVisible(false)

        setRemoveDeathVisible(false)
        setEstrangedDeathVisible(false)
        setDeceasedDeathVisible(false)
      }
      else if (value === "Estranged") {
        setIsCharityVisible(true);
        setContactInformationVisible(false)
        setAddressVisible(false)
        setRelationshipVisible(false)

        setEstrangedDeathVisible(true)
        setRemoveDeathVisible(false)
        setDeceasedDeathVisible(false)
      }
      else if (value === "Remove") {
        setIsCharityVisible(true);
        setContactInformationVisible(false)
        setAddressVisible(false)
        setRelationshipVisible(false)

        setRemoveDeathVisible(true)
        setEstrangedDeathVisible(false)
        setDeceasedDeathVisible(false)

      }
      else {
        // setIsFirstMiddelLastSuffixKnownNickVisible(true);
      }
    }

    if (name === "Zipcode") {
      if (value.length === 5) {
        // if (value.length === 5 || value.length === 3) {
        try {
          const response = await fetch(`${baseURL}/zip?Zipcode=${value}`);

          // Check if the API call was successful
          if (response.ok) {
            const responseData = await response.json();
            // Check if the response indicates success or failure
            if (responseData.success) {
              // Set zipResponseSuccess state to true if Zip code was found
              setZipResponseSuccess(true);

            } else {
              // Set zipResponseSuccess state to false if Zip code was not found
              setZipResponseSuccess(false);
              // Display an alert if Zip code was not found
              alert(responseData.message);
            }
          } else if (response.status === 404) {
            // Set zipResponseSuccess state to false if Zip code was not found
            setZipResponseSuccess(false);
            // Display an alert if Zip code was not found
            setDialogTitle('Error');
            setIsError(true);
            setDialogMessage('Zip code not found');
            setIsDialogVisible(true);
            return;

          } else {
            // Set zipResponseSuccess state to false for other status code
            setZipResponseSuccess(false);
            // console.log("Unexpected status code:", response.status);
          }
        } catch (error) {
          // Set zipResponseSuccess state to false if there was an error
          setZipResponseSuccess(false);
          // Handle error
          console.error("Error fetching data:", error);
        }
      } else {
        // Reset zipResponseSuccess state if the Zip code length is not 5
        setZipResponseSuccess(false);
      }
    }

    if (name === "Mobile" && /^\d+$/.test(value)) {

      const phoneNumber = value.replace(/\D/g, '');
      const formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

      setPlan(prevData => ({
        ...prevData,
        [name]: formattedPhoneNumber
      }));
    } 
    
    // else if (name === "YOB") {
    //   setPlan(prevData => ({
    //     ...prevData,
    //     [name]: value
    //   }));
    // }

    else if (name === "Relationship Order") {

      setPlan(prevData => ({
        ...prevData,
        "Relationship Order": value
      }));

    }

    else {

      setPlan(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleUSCitizenChange = (value) => {
    setPlan(prevData => ({
      ...prevData,
      "US Citizen": value
    }));
  };

  const handleMaritalStatusChange = (value) => {
    setPlan(prevData => ({
      ...prevData,
      "Marital Status": value
    }));
  };

  const handleDateChange = (date, fieldName) => {
    setPlan(prevData => ({
      ...prevData,
      [fieldName]: date,
    }));

  };

  const handleOk = () => {
    setIsSuccessVisible(false);
    navigate('/user/planList');
  };

  return (
    <>
      <div className='plan-list'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h1 className='my-5 text-white'>Contact Information Update</h1>
            </div>
            <div className='col-md-12'>
              <div className='d-md-flex align-items-end'>
                <div className="col-md-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label text-white">Name</label>
                  <input
                    type="text"
                    className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                    disabled value={plan ? plan["Plan Name"] : ''}
                  />
                </div>
                <div className='col-md-auto ms-5'>
                  <button
                    className="btn btn-primary rounded-2 text-white px-5 mt-2"
                    onClick={handleBackClick}>
                    Back to Summary
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='bg-darkInfo p-5 mt-4 text-white'>

            {/* <div className='col-md-6 mb-4'>
              <h6 className='text-white'>Name as listed on driver’s license or government id</h6>
            </div> */}

            <div className='col-md-12 mb-4'>
              <div className='row m-0 justify-content-between align-items-center'>
                <div className='col-md-4 mb-4'>
                  {/* <h6 className='text-white name-legal'>Name as listed on driver’s <br/> license or government id</h6> */}
                  <div class="name-legal">
                    <div class="name-legal-inner">
                      <h6 className='text-white' style={{fontSize:"18px", textAlign:"center"}}>Enter name as listed on driver's <br/> license or government id</h6>
                    </div>
                  </div>
                </div>
                <div className='col-md-5 mb-4 contact-status'>
                  <div className='d-flex align-items-center contact-status-inner'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white col-md-auto me-3">Confirm Contact Status:</label>
                    <select className="form-select rounded-1 inp px-3 mb-0 w-100 selectCustom bgDarred"
                      // name="contactStatus" 
                      // onChange={handleInputChange}
                      name="contactStatus"
                      value={plan ? plan.contactStatus : ""}
                      onChange={handleInputChange}
                    >

                    {contactStatusOptions
                    .filter(option => visibleOptions.includes(option.value))
                    .map(option => (
                      <option
                        key={option.value}
                        value={option.value}>
                        {option.label}
                      </option>
                    ))}

                    </select>
                  </div>
                </div>
              </div>
            </div>

            {!isCharityVisible && (
              <div className='Charity'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='row mb-3'>
                      <div className='col-md-12 mb-3'>
                        <label htmlFor="exampleFormControlInput1" className="form-label text-white">Charity Name *</label>
                        <input type="text" placeholder='Enter Charity Name'
                          className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                          id="otherNameInput"
                          // name="Charity Name"
                          // onChange={handleInputChange}
                          name="Charity Name" value={plan ? plan["Charity Name"] : ''} onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isCharityVisible && (
              <div className='peronalDetail'>
                <div className='row'>
                  <div className='col-md-3 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">First Name *</label>
                    <input type="text" placeholder='Enter first name'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="First Name" 
                      value={plan ? plan["First Name"] : ''} 
                      onChange={handleInputChange}
                    // value={formData["First Name"]}
                    />
                  </div>
                  <div className='col-md-3 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Middle Initial</label>
                    <input type="text" placeholder='Enter middle initial'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="M" 
                      value={plan ? plan["M"] : ''} 
                      onChange={handleInputChange}
                    // value={formData["M"]}
                    />
                  </div>
                  <div className='col-md-3 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Last Name *</label>
                    <input type="text" placeholder='Enter last name'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Last Name" 
                      onChange={handleInputChange}
                      value={plan ? plan["Last Name"] : ''} 
                    // value={formData["Last Name"]}
                    />
                  </div>
                  <div className='col-md-3 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Suffix</label>
                    <select className="form-select rounded-1 inp px-3 mb-0 w-100 selectCustom" 
                    name="Suffix" 
                    onChange={handleInputChange}
                    value={plan ? plan["Suffix"] : ""}
                    >
                      <option value="">Select</option>
                      <option value="Sr.">Sr.</option>
                      <option value="Jr.">Jr.</option>
                    </select>
                  </div>
                  <div className='col-md-6 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Also known as</label>
                    <input type="text" placeholder='Also known as Enter name you are also known as'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      id="otherNameInput"
                      name="Another Name Value"
                      value={plan ? plan["Another Name Value"] : ''}
                      onChange={handleInputChange}
                    // value={formData["Another Name Value"]}
                    />
                  </div>
                  <div className='col-md-6 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Nickname</label>
                    <input type="text" placeholder='Enter nickname'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Nickname"
                      value={plan ? plan["Nickname"] : ''} 
                      onChange={handleInputChange}
                    // value={formData["Nickname"]}
                    />
                  </div>
                </div>
              </div>
            )}

            {(isCharityVisible && isRelationshipVisible) && (
              <div className='relationshipDetail'>
                <div className='row'>
                  <div className='col-md-3 mb-3'>
                    <label htmlFor="relationship" className="form-label text-white">Relationship *</label>
                    <select className="form-select rounded-1 inp px-3 mb-0 w-100 selectCustom"
                      name="Relationship Type"
                      value={plan ? plan["Relationship Type"] : ""}
                      // value={formData["Relationship Type"]}
                      onChange={handleRelationshipChange}>
                      <option value="">Select</option>
                      <option value="Parent">Parent</option>
                      <option value="Spouse">Spouse</option>
                      <option value="Grandchild">Grandchild</option>
                      <option value="Child">Child</option>
                      <option value="Sibling">Sibling</option>
                      <option value="Niece/Nephew">Niece/Nephew</option>
                      <option value="Cousin">Cousin</option>
                      <option value="Friend">Friend</option>
                      <option value="Professionals">Professionals</option>
                      <option value="Primary Contact">Primary Contact</option>
                      {/* <option value="Charity">Charity</option> */}
                    </select>
                  </div>

                  <div className='col-md-3 mb-3'>
                    <label htmlFor="subRelationship" className="form-label text-white">Sub Relationship *</label>
                    <select className="form-select rounded-1 inp px-3 mb-0 w-100 selectCustom"
                      name="Relationship Order"
                      onChange={handleInputChange}
                      value={plan ? plan["Relationship Order"] : ""}
                    // value={formData["Relationship Order"]}
                    >
                      <option value="">Select</option>
                      {subRelationshipOptions}
                    </select>
                  </div>

                  {showParentDropdown && (
                    <div className='col-md-6 mb-3'>
                      <label htmlFor="relationship" className="form-label text-white">Parent *</label>
                      <select className="form-select rounded-1 inp px-3 mb-0 w-100 selectCustom"
                        name="Parent"
                        onChange={handleParentChange}
                        value={plan ? plan["Parent"] : ""}
                      // value={formData["Parent"]}
                      >
                        <option value="">Select</option>
                        {fullNames.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                        <option value="N/A">N/A</option>
                      </select>
                    </div>
                  )}

                </div>
              </div>
            )}

            {isContactInformationVisible && (
              <div className="contact-information mb-3">
                <div className='row'>
                  <div className='col-md-6'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Enter phone number *</label>
                    <input type="text" placeholder='(###) ###-####'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Mobile"
                      // onChange={handleInputChange}
                      value={plan ? Constants.formatPhoneNumber(plan["Mobile"]) : ''}
                      onChange={handleInputChange}
                      maxLength={14}
                    // value={formData["Mobile"]}
                    />
                  </div>


                  <div className='col-md-6'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Email Address *</label>
                    <input type="text" placeholder='Enter email address'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Email"
                      value={plan ? plan["Email"] : ''}
                      onChange={handleInputChange}
                    // onChange={handleInputChange}
                    // value={formData["Email"]}
                    />
                  </div>

                </div>
              </div>
            )}

            {(isCharityVisible && isRelationshipVisible) && (
              <div className='demographic-information mb-3'>
                <div className='row'>

                  <div className='col-md-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Enter year of Birth *</label>
                    <input type="text" placeholder='####'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="YOB"
                      value={plan ? plan["YOB"] : ''}
                      onChange={handleInputChange}
                      maxLength={4}
                      onKeyPress={(e) => {
                        const onlyDigits = /[0-9]/;
                        const key = e.key;
                        if (!onlyDigits.test(key)) {
                          e.preventDefault();
                        }
                      }}
                    />

                    {/* <DatePicker
                      selected={plan["YOB"] ? new Date(plan["YOB"]) : null}
                      onChange={(date) => handleDateChange(date, 'YOB')}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="Select Date"
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="YOB"
                      isClearable
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={15}
                      dropdownMode="select"
                      onKeyDown={(e) => e.preventDefault()}
                      autoComplete="off"
                    /> */}
                  </div>

                  <div className='col-md-3 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">U.S Citizen? *</label>
                    <div className='d-flex gap-3'>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="US Citizen"
                          id="noCitizen"
                          autoComplete="off"
                          onChange={() => handleUSCitizenChange('Yes')}
                          checked={plan && plan["US Citizen"] === 'Yes'}
                        // checked={formData["US Citizen"] === 'Yes'}
                        />
                        <label className="form-check-label" htmlFor="noCitizen">
                          Yes
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="US Citizen"
                          id="noCitizen"
                          autoComplete="off"
                          onChange={() => handleUSCitizenChange('No')}
                          checked={plan && plan["US Citizen"] === 'No'}
                        // checked={formData["US Citizen"] === 'No'}
                        />
                        <label className="form-check-label" htmlFor="noCitizen">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  {isMaritalStatusVisible && (
                    <div className='col-md-6 mb-3'>
                      <label htmlFor="exampleFormControlInput1" className="form-label text-white">Marital Status *</label>
                      <div className='d-flex gap-3'>

                        <div className='row'>

                          <div className='col-md-3'>
                            <div className="form-check">
                              <input className="form-check-input" type="radio" name="Marital Status"
                                id="marriedStatus"
                                autoComplete="off"
                                onChange={() => handleMaritalStatusChange('Married')}
                                checked={plan && plan["Marital Status"] === 'Married'}
                              />
                              <label className="form-check-label" htmlFor="marriedStatus">
                                Married
                              </label>
                            </div>

                            <div className="form-check">
                              <input className="form-check-input" type="radio" name="Marital Status"
                                id="marriedStatus"
                                autoComplete="off"
                                onChange={() => handleMaritalStatusChange('Widowed')}
                                checked={plan && plan["Marital Status"] === 'Widowed'}
                              // checked={formData["Marital Status"] === 'Widowed'}
                              />
                              <label className="form-check-label" htmlFor="marriedStatus">
                                Widowed
                              </label>
                            </div>

                          </div>

                          <div className='col-md-3'>

                            <div className="form-check">
                              <input className="form-check-input" type="radio" name="Marital Status"
                                id="marriedStatus"
                                autoComplete="off"
                                onChange={() => handleMaritalStatusChange('Divorced')}
                                checked={plan && plan["Marital Status"] === 'Divorced'}
                              // checked={formData["Marital Status"] === 'Divorced'}
                              />
                              <label className="form-check-label" htmlFor="marriedStatus">
                                Divorced
                              </label>
                            </div>

                            <div className="form-check">
                              <input className="form-check-input" type="radio" name="Marital Status"
                                id="marriedStatus"
                                autoComplete="off"
                                onChange={() => handleMaritalStatusChange('Single')}
                                checked={plan && plan["Marital Status"] === 'Single'}
                              // checked={formData["Marital Status"] === 'Single'}
                              />
                              <label className="form-check-label" htmlFor="marriedStatus">
                                Single
                              </label>
                            </div>


                          </div>

                          <div className='col-md-3'>
                            <div className="form-check">
                              <input className="form-check-input" type="radio" name="Marital Status"
                                id="separatedStatus"
                                autoComplete="off"
                                onChange={() => handleMaritalStatusChange('Separated')}
                                checked={plan && plan["Marital Status"] === 'Separated'}
                              // checked={formData["Marital Status"] === 'Separated'}
                              />
                              <label className="form-check-label" htmlFor="separatedStatus">
                                Separated
                              </label>
                            </div>

                            <div className="form-check">
                              <input className="form-check-input" type="radio" name="Marital Status"
                                id="remarriedStatus"
                                autoComplete="off"
                                onChange={() => handleMaritalStatusChange('Remarried')}
                                checked={plan && plan["Marital Status"] === 'Remarried'}
                              // checked={formData["Marital Status"] === 'Remarried'}
                              />
                              <label className="form-check-label" htmlFor="remarriedStatus">
                                Remarried
                              </label>
                            </div>

                          </div>

                          <div className='col-md-3'>

                            <div className="form-check">
                              <input className="form-check-input" type="radio" name="Marital Status"
                                id="lifePartnerStatus"
                                autoComplete="off"
                                onChange={() => handleMaritalStatusChange('Life Partner')}
                                checked={plan && plan["Marital Status"] === 'Life Partner'}
                              // checked={formData["Marital Status"] === 'Life Partner'}
                              />
                              <label className="form-check-label" htmlFor="lifePartnerStatus">
                                Life Partner
                              </label>
                            </div>

                          </div>

                        </div>

                      </div>
                    </div>
                  )}

                </div>
              </div>
            )}

            {/* // value={formatDateForInputField(formData["Date of Birth"])} */}

            {/* {isCharityVisible && (
          <div className="death-information">
            <div className='row'>
            <div className='col-md-3 mb-3'>
                    <label htmlFor="relationship" className="form-label text-white">Date of Death</label>
                    <input type="date" 
                      placeholder=''
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Date of Death"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className='col-md-3 mb-3'>
                    <label htmlFor="subRelationship" className="form-label text-white">Place of Death</label>
                    <input type="text" placeholder='Enter Place'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Place of Death"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className='col-md-6 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Residency at Time of Death</label>
                    <input type="text" placeholder='Enter Residency'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Residency of Death" 
                      onChange={handleInputChange}
                    />
                  </div>

            </div>
          </div>
          )} */}

            {isDeceasedDeathVisible && (
              // <div className="death-information">
              //   <div className='row'>
              //   <div className='col-md-12 mb-3'>
              //           <label htmlFor="relationship" className="form-label text-white">Date of Deceased</label>
              //           <input type="date" 
              //             placeholder=''
              //             className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
              //             name="Date of Death"
              //             onChange={handleInputChange}
              //           />
              //         </div>
              //   </div>
              // </div>
              <div className="death-information">
                <div className='row'>
                  <div className='col-md-3 mb-3'>
                    <label htmlFor="relationship" className="form-label text-white">Date of Death  *</label>
                    <DatePicker
                      selected={plan["Date of Death"] ? new Date(plan["Date of Death"]) : null}
                      onChange={(date) => handleDateChange(date, 'Date of Death')}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="Select Date"
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Date of Death"
                      isClearable
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={15}
                      dropdownMode="select"
                      onKeyDown={(e) => e.preventDefault()}
                      autoComplete="off"
                    />
                  </div>

                  <div className='col-md-3 mb-3'>
                    <label htmlFor="subRelationship" className="form-label text-white">Place of Death *</label>
                    <input type="text" placeholder='Enter Place'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Place of Death"
                      onChange={handleInputChange}
                    // value={formData["Place of Death"]}
                    />
                  </div>

                  <div className='col-md-6 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Residency at Time of Death  * </label>
                    <input type="text" placeholder='Enter Residency'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Residency of Death"
                      onChange={handleInputChange}
                    // value={formData["Residency of Death"]}
                    />
                  </div>

                </div>
              </div>
            )}

            {isEstrangedDeathVisible && (
              <div className="death-information">
                <div className='row'>
                  <div className='col-md-6 mb-3'>
                    <label htmlFor="relationship" className="form-label text-white">Date of Estrangement *</label>
                    <DatePicker
                      selected={plan["Date of Estranged"] ? new Date(plan["Date of Estranged"]) : null}
                      onChange={(date) => handleDateChange(date, 'Date of Estranged')}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="Select Date"
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Date of Estranged"
                      isClearable
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={15}
                      dropdownMode="select"
                      onKeyDown={(e) => e.preventDefault()}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            )}

            {isRemoveDeathVisible && (
              <div className="death-information">
                <div className='row'>
                  <div className='col-md-6 mb-3'>
                    <label htmlFor="relationship" className="form-label text-white">Date of Remove  *</label>
                    <DatePicker
                      selected={plan["Date of Remove"] ? new Date(plan["Date of Remove"]) : null}
                      onChange={(date) => handleDateChange(date, 'Date of Remove')}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="Select Date"
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Date of Remove"
                      isClearable
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={15}
                      dropdownMode="select"
                      onKeyDown={(e) => e.preventDefault()}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            )}

            {isAddressVisible && (
              <div className='address-information mb-3'>
                <div className='row'>

                  <div className='col-md-6'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Address Line 1 *</label>
                    <input type="text" placeholder='Enter address line 1'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Address 1"
                      value={plan ? plan["Address 1"] : ''}
                      onChange={handleInputChange}
                    // onChange={handleInputChange}
                    // value={formData["Address 1"]}
                    />
                  </div>

                  <div className='col-md-6'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Address Line 2</label>
                    <input type="text" placeholder='Enter address line 2'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Address 2"
                      value={plan ? plan["Address 2"] : ''}
                      onChange={handleInputChange}
                    // onChange={handleInputChange}
                    // value={formData["Address 2"]}
                    />
                  </div>

                  <div className='col-md-6 mt-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">City *</label>
                    <input type="text" placeholder='Enter city'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="City"
                      value={plan ? plan["City"] : ''}
                      onChange={handleInputChange}
                    // onChange={handleInputChange}
                    // value={formData["City"]}
                    />
                  </div>

                  <div className='col-md-3 mt-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">State *</label>
                    <input type="text" placeholder='Enter state'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="State"
                      value={plan ? plan["State"] : ''}
                      onChange={handleInputChange}
                    // onChange={handleInputChange}
                    // value={formData["State"]}
                    />
                  </div>
                  <div className='col-md-3 mt-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Zip Code *</label>
                    <input type="text" placeholder='Enter zip code'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      maxLength={5}
                      name="Zipcode" value={plan ? plan["Zipcode"] : ''}
                      onChange={handleInputChange}
                    // onChange={handleInputChange}
                    // value={formData["Zipcode"]}
                    />
                  </div>

                </div>
              </div>
            )}
            <div className='col-md-12 text-end '>
              <button className="btn btn-primary rounded-2 text-white px-5 mt-2" onClick={() => updatePlan(plan._id)}>Press To Approve</button>
            </div>

          </div>
        </div>
      </div>

      <Modal 
      className="customModalContent" 
      isOpen={isDialogVisible} 
      onRequestClose={() => setIsDialogVisible(false)}
      >
        <MessageDialog
          title={dialogTitle}
          message={dialogMessage}
          isError={isError}
          onOk={handleOk}
          onClose={() => setIsDialogVisible(false)}
        />
      </Modal>

      <Modal className="customModalContent" isOpen={isSuccessVisible} onRequestClose={() => setIsSuccessVisible(false)}>
        <SucessValidate message={errorMessage} onClose={() => setIsSuccessVisible(false)} onOk={handleOk} />
      </Modal>

    </>
  );

};

export default EditPlan;
