import './NavigationTabs.css';

function NavigationTabs() {

  const handleReviewButtonClick = () => {
    window.open('https://www.avvo.com/attorneys/02061-ma-patricia-shumaker-1724469/reviews.html', '_blank');
  };

  return (
   <div className='col-md-12'>
            <nav className='bg-dark navTabLight'>
              <div className="nav nav-tabs justify-content-between" id="nav-tab" role="tablist">
                <button className="nav-link flex-fill px-5 py-3 text-white border-0 rounded-0 active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
                <button className="nav-link flex-fill px-5 py-3 text-white border-0 rounded-0" id="nav-practice-tab" data-bs-toggle="tab" data-bs-target="#nav-practice" type="button" role="tab" aria-controls="nav-practice" aria-selected="false">Practice Areas</button>
                <button className="nav-link flex-fill px-5 py-3 text-white border-0 rounded-0" id="nav-attorney-tab" data-bs-toggle="tab" data-bs-target="#nav-attorney" type="button" role="tab" aria-controls="nav-attorney" aria-selected="false">Attorney</button>
                <button className="nav-link flex-fill px-5 py-3 text-white border-0 rounded-0" id="nav-locations-tab" data-bs-toggle="tab" data-bs-target="#nav-locations" type="button" role="tab" aria-controls="nav-locations" aria-selected="false">Locations</button>
                <button className="nav-link flex-fill px-5 py-3 text-white border-0 rounded-0" type="button" role="tab" aria-selected="false" 
                onClick={handleReviewButtonClick}>Reviews</button>
              </div>
            </nav>
    </div>
  );
}

export default NavigationTabs;