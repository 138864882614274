import React from 'react';

const Practice = () => {

  return (
    <div className="tab-pane fade" id="nav-practice" role="tabpanel" aria-labelledby="nav-practice-tab">
      <div className='rightSec p-md-5 p-3'>
        <div className='row margin_50px'>
          <div className='col-md-12 text-white'>
            <div className='row gap-column-4'>
              <div className='col-md-6 mb-4'>
                <img className='me-2' src='./images/estate-planning.png' height={60} alt='' />
                <h6 className='mt-3 mb-2 fs-23'>Estate Planning</h6>
                <p className='textAlign'>Involves developing easy-to-follow instructions to protect your wealth during your lifetime,
                  authorize the people you trust to support your  decisions and pass your assets to your
                  loved ones upon your  death in a simple, tax-effective, private way.</p>
              </div>
              <div className='col-md-6 mb-4'>
                <img className='me-2' src='./images/trust.png' height={60} alt='' />
                <h6 className='mt-3 mb-2 fs-23'>Trust and Estate Administration</h6>
                <p className='textAlign'>Involves assisting individuals charged with the responsibility of managing assets,
                  filing tax returns and making distributions to beneficiaries to ensure that all legal and tax requirements are met for a speedy,
                  cost-effective transition of wealth.</p>
              </div>
              <div className='col-md-6 mb-4'>
                <img className='me-2' src='./images/elder-law.png' height={60} alt='' />
                <h6 className='mt-3 mb-2 fs-23'>Elder Law and Long-term Care</h6>
                <p className='textAlign'>Involves developing a plan to protect your wealth in the event you need nursing care
                  during your lifetime.  Massachusetts has excellent protection for married couples in this situation and
                  knowing the rules prior to a change in health is essential to protecting your wealth.</p>
              </div>
              <div className='col-md-6 mb-4'>
                <img className='me-2' src='./images/estate-plan.png' height={60} alt='' />
                <h6 className='mt-3 mb-2 fs-23'>Estate Plan Implementation and Funding</h6>
                <p className='textAlign'>Involves titling your assets in a way that will avoid Probate, ensure the
                  application of full tax exemptions and protect your wealth for future generations.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default Practice;
