import './Header.css';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Header() {

  const navigate = useNavigate();
  const handleUserLogout = () => {
    localStorage.removeItem('userToken');
    navigate('/');
    window.location.reload();
  };

  const handleAdminTokenLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin/login');
    window.location.reload();
  };

  // Check if token exists in local storage
  const userToken = localStorage.getItem('userToken');
  const adminToken = localStorage.getItem('adminToken');

  const location = useLocation();
  const pathname = location.pathname;

  const isHeaderUserVisible = !(pathname === '/user/login');
  const isHeaderAdminVisible = !(pathname === '/admin/login');

    let linkTo = '/';
    if (adminToken) {
        linkTo = '/admin/plan';
    } else if (userToken) {
        linkTo = '/user/planList';
    }

  return (
    <>
      <nav className="navbar bg-custom header py-3">
        <div className="container">
          <div className='row align-items-center w-100'>
            <div className='col-md'>
             <Link to={linkTo}>
                <img src="/images/logo.png" className='img-fluid' alt="Logo" />
             </Link>
            </div>

            <div className='col-md-auto text-center'>
              <p className='mb-0'><b>We’re excited to announce that we are now <br/> part of Conn Kavanaugh LLP.</b>
              </p>
              {userToken && isHeaderUserVisible && !adminToken && (
                  <button className="btn btn-primary rounded-2 text-white px-5 mt-2" onClick={handleUserLogout}>
                    Sign Out Now
                  </button>
                )}

                {adminToken && isHeaderAdminVisible && (
                  <button className="btn btn-primary rounded-2 text-white px-5 mt-2" onClick={handleAdminTokenLogout}>
                    Sign Out Now
                  </button>
                )}

            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
