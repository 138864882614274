import axios from 'axios';
import Constants from '../utilities/constants'; 
import { getToken } from './tokenService'; 

const baseURL = Constants.BASE_URL + '/plan';

export const createClient = async (clientData) => {
  try {
    const response = await axios.post(baseURL, clientData);
    return response.data;
  } catch (error) {
    console.error('Error creating client:', error);
    throw error;
  }
};

export const getPlanList = async ( page = 1, limit = 10 ) => {
  try {
    const token = getToken();
    const response = await axios.get(`${baseURL}?page=${page}&limit=${limit}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
   
    return response.data;

  } catch (error) {
    console.error('Error fetching client:', error);
    throw error;
  }
};

export const getPlanListV1 = async ( page = 1, limit = 10 ) => {
  try {
    const token = getToken();
    // const response = await axios.get(`${baseURL}?page=${page}&limit=${limit}`,{
    const response = await axios.get(`${baseURL}/list/v1?page=${page}&limit=${limit}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
   
    return response.data;

  } catch (error) {
    console.error('Error fetching client:', error);
    throw error;
  }
};

export const getPlanById = async ( id ) => {
  try {
    const token = getToken();
    const response = await axios.get(`${baseURL}/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching client:', error);
    throw error;
  }
};

export const updatePlanById = async ( planId, planData ) => {
  try {
    const token = getToken();
    const response = await axios.put(`${baseURL}/${planId}`, planData,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
    // Handle success if needed
  } catch (error) {
    console.error('Error updating plan:', error);
    // Handle error if needed
  }
};

export const deletePlanById = async ( planId, planData ) => {
  try {
    const token = getToken();
    const response = await axios.delete(`${baseURL}/${planId}`,{
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
    // console.log(response.data.message);

  } catch (error) {
    console.error('Error Deleting plan:', error);
  }
};

export const createPlanByName = async ( planData ) => {
  try {
    // const token = getToken();
    const response = await axios.post(`${baseURL}`, planData,{
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
    // console.log(response.data.message); 
    return response.data;
    // Handle success if needed
  } catch (error) {
    console.error('Error updating plan:', error);
    // Handle error if needed
  }
};

export const getPlanParent = async (planName, relationshipType) => {
  try {
    const token = getToken();
    const response = await axios.get(`${baseURL}/planParent`, {
      params: {
        planName: planName,
        relationshipType: relationshipType
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("response",response)
    return response.data.data;
  } catch (error) {
    console.error('Error fetching client:', error);
    throw error;
  }
};

export const getPlanRecordEmail = async ( planName ) => {
  try {
    const token = getToken();
    // const response = await axios.get(`${baseURL}/planRecord?planeName=${planName}`,{
      const response = await axios.get(`${baseURL}/planRecord/v2?planeName=${planName}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
   
    return response.data;

  } catch (error) {
    console.error('Error fetching client:', error);
    throw error;
  }
};

