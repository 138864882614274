import React, { useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { loginPlan } from '../../../services/loginService';
import Constants from '../../../utilities/constants';
import { Navigate } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import LoginHelp from '../../../components/Modal/LoginHelp';
import MessageDialog from '../../../components/Modal/MessageDialog';
import Modal from 'react-modal';

const Login = () => {
  const { userloggedIn } = Constants.getTokens();

  const [isLoginHelpVisible, setIsLoginHelpVisible] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [SSN_First_4, setSSN_First_4] = useState('');
  const [YOB, setYOB] = useState('');

  if (userloggedIn) {
    return <Navigate to={"/user/planList"} />
  }

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent form submission
    try {

      if (!email) {

        setDialogTitle('Error');
        setDialogMessage('Email is required.');
        setIsDialogVisible(true);
        return;
      }

      if (!SSN_First_4) {

        setDialogTitle('Error');
        setDialogMessage('SSN is required');
        setIsDialogVisible(true);
        return;
      }

      if (!YOB) {

        setDialogTitle('Error');
        setDialogMessage('YOB is required');
        setIsDialogVisible(true);
        return;
      }

      const response = await loginPlan({
        "Email": email,
        "SSN First 4": SSN_First_4,
        "YOB": YOB,
      });
      // console.log(response);

      if (response && response.data) {
        const { token } = response.data;
        localStorage.setItem('userToken', token);
        navigate('/user/planList');
        window.location.reload();
      } else {
        setErrorMessage('The information you entered does not match our system. Please try again or click the button for login help where you can schedule a convenient time for our staff to assist you.');
        setIsLoginHelpVisible(true);
      }

    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  return (
    <>
      <Header />
      <div className='main-wrapper p-3 h-100'>
        <div className='d-flex justify-content-center align-items-center h-100'>
          <div className='login p-5'>
            <div className='col-md-12 text-center'>
              {/* <img src="/images/logo.png" className='w-100 login' alt="Logo" /> */}
              <h1 className='my-5 text-white'>Contact Information Update</h1>
            </div>
            <div className='row gy-4'>
              <div className='col-md-12'>
                <label htmlFor="username" className="form-label text-white">Email</label>
                <input type="text" className="form-control rounded-1 inp px-3" id="username" value={email}
                  onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className='col-md-6'>
                <label htmlFor="username" className="form-label text-white">Last Four Digits of SSN</label>
                <input type="text" className="form-control rounded-1 inp px-3" id="username" maxLength={4} value={SSN_First_4}
                  onChange={(e) => setSSN_First_4(e.target.value.replace(/\D/g, ''))} />
              </div>
              <div className='col-md-6'>
                <label htmlFor="password" className="form-label text-white">Year of Birth</label>
                <input type="text" className="form-control rounded-1 inp px-3" maxLength={4} id="YOB" value={YOB}
                   onChange={(e) => setYOB(e.target.value.replace(/\D/g, ''))} 
                   inputMode="numeric" />
              </div>
              <div className='col-md-12 text-center'>
                <button onClick={(e) => handleLogin(e)} className="btn btn-primary rounded-2 text-white px-5 mt-2">
                  Login Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="customModalContent" isOpen={isLoginHelpVisible} onRequestClose={() => setIsLoginHelpVisible(false)}>
        <LoginHelp message={errorMessage} onClose={() => setIsLoginHelpVisible(false)} />
      </Modal>

      <Modal className="customModalContent" isOpen={isDialogVisible} onRequestClose={() => setIsDialogVisible(false)}>
        <MessageDialog title={dialogTitle} message={dialogMessage} isError={true} onClose={() => setIsDialogVisible(false)} />
      </Modal>

    </>
  );

};

export default Login;
