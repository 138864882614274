import React, { useEffect, useState } from 'react';
import './AdminPlanDetail.css';
import { useParams } from 'react-router-dom';
import { getPlanByIdSingleRecord } from '../../../services/adminService';
import { useNavigate } from 'react-router-dom';
import Constants from '../../../utilities/constants';

const AdminPlanDetail = () => {
  const navigate = useNavigate();
  const { planId } = useParams();
  const [plan, setPlan] = useState(null);

  useEffect(() => {
    const fetchPlanById = async () => {
      try {
        const data = await getPlanByIdSingleRecord(planId);
        setPlan(data);
        // console.log(data, "datadata")
      } catch (error) {
        console.error('Error fetching plan details:', error);
      }
    };

    fetchPlanById();
  }, [planId]);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className='plan-list'>
        <div className='container'>
          <div className='row justify-content-between'>
          <div className='col-md-12'>
              <h1 className='my-3 text-white'>Plan Information</h1>
            </div>
            <div className='col-md-auto ms-5'>
                <button 
                  className="btn btn-primary rounded-2 text-white px-5 mt-2" 
                  onClick={handleBackClick}
                  >
                    Back to Summary
                  </button>
                  </div>
            <div className='col-md-12'>
              <div className="table-responsive">
              {plan !== null ? (
                <table className="table custom-table table-bordered mt-4 table-hover">
                  <tbody>
                  {plan && plan["Plan Name"] && (  
          <tr>
            <td><strong>Plan Name</strong></td>
            <td>{plan["Plan Name"] || ""}</td>
          </tr>
          )}

          {plan && plan["Review"] && (  
          <tr>
            <td><strong>Review</strong></td>
            <td>{plan["Review"]}</td>
          </tr>
          )} 

          {plan && plan["Approved By Date & Time"] && (  
          <tr>
            <td><strong>Approved By Date</strong></td>
            <td>{Constants.formatDate(plan["Approved By Date & Time"])}</td>
          </tr>
          )}

          {plan && plan["Charity Name"] && (  
          <tr>
            <td><strong>Charity Name</strong></td>
            <td>{plan["Charity Name"] || ""}</td>
          </tr>
          )}

          {plan && plan["First Name"] && (
          <tr>
            <td><strong>First Name</strong></td>
            <td>{plan["First Name"] || ""}</td>
          </tr>
          )}

          {plan && plan["Last Name"] && (
          <tr>
            <td><strong>Last Name</strong></td>
            <td>{plan["Last Name"] || ""}</td>
          </tr>
          )}

        {plan && plan["Suffix"] && (
          <tr>
            <td><strong>Suffix</strong></td>
            <td>{plan["Suffix"] || ""}</td>
          </tr>
          )}

          {plan && plan["M"] && (
          <tr>
            <td><strong>M</strong></td>
            <td>{plan["M"] || ""}</td>
          </tr>
          )}

          {plan && plan["Marital Status"] && (
          <tr>
            <td><strong>Marital Status</strong></td>
            <td>{plan["Marital Status"] || ""}</td>
          </tr>
          )}


          {plan && plan["Email"] && (
          <tr>
            <td><strong>Email</strong></td>
            <td>{plan["Email"] || ""}</td>
          </tr>
          )}
          

          {plan && plan["YOB"] && (
          <tr>
            <td><strong>YOB</strong></td>
            <td>{plan["YOB"] || ""}</td>
          </tr>
          )}

          {plan && plan["SSN First 4"] && (
          <tr>
            <td><strong>SSN First 4</strong></td>
            <td>{plan["SSN First 4"] || ""}</td>
          </tr>
          )}


          {plan && plan["Mobile"] && (
          <tr>
            <td><strong>Mobile</strong></td>
            <td>{Constants.formatPhoneNumber(plan["Mobile"])}</td>
          </tr>
          )}

          {plan && plan["Office formated"] && (
          <tr>
            <td><strong>Office formated</strong></td>
            <td>{plan["Office formated"] || ""}</td>
          </tr>
          )}

          {plan && plan["Home"] && ( 
          <tr>
            <td><strong>Home</strong></td>
            <td>{plan["Home"] || ""}</td>
          </tr>
           )}

          {plan && plan["Home formated"] && ( 
          <tr>
            <td><strong>Home formated</strong></td>
            <td>{plan["Home formated"] || ""}</td>
          </tr>
           )}

            {plan && plan["Fax"] && (   
          <tr>
            <td><strong>Fax</strong></td>
            <td>{plan["Fax"] || ""}</td>
          </tr>
            )}

          {plan && plan["Fax formated"] && (   
          <tr>
            <td><strong>Fax formated</strong></td>
            <td>{plan["Fax formated"] || ""}</td>
          </tr>
          )}

          {plan && plan["Assigned To"] && ( 
          <tr>
            <td><strong>Assigned To</strong></td>
            <td>{plan["Assigned To"] || ""}</td>
          </tr>
          )} 

          {plan && plan["Updated As Of"] && ( 
          <tr>
            <td><strong>Updated As Of</strong></td>
            <td>{Constants.formatDate(plan["Updated As Of"])}</td>
          </tr>
          )} 

          {plan && plan["Relationship Type"] && ( 
          <tr>
            <td><strong>Relationship Type</strong></td>
            <td>{plan["Relationship Type"] || ""}</td>
          </tr>
          )} 

          {plan && plan["Relationship Order"] && ( 
          <tr>
            <td><strong>Relationship Order</strong></td>
            <td>{plan["Relationship Order"] || ""}</td>
          </tr>
          )} 

          {plan && plan["Social Security Number"] && (
          <tr>
            <td><strong>Social Security Number</strong></td>
            <td>{plan["Social Security Number"] || ""}</td>
          </tr>
          )}

          {plan && plan["Date of Death"] && (
          <tr>
            <td><strong>Date of Death</strong></td>
            <td>{plan["Date of Death"] || ""}</td>
          </tr>
          )}  

          {plan && plan["Spouse's First Name"] && (
          <tr>
            <td><strong>Spouse's First Name</strong></td>
            <td>{plan["Spouse's First Name"] || ""}</td>
          </tr>
          )} 

          {plan && plan["Spouse's Full Legal Name"] && ( 
          <tr>
            <td><strong>Spouse's Full Legal Name</strong></td>
            <td>{plan["Spouse's Full Legal Name"] || ""}</td>
          </tr>
          )} 

          {plan && plan["Spouse's Cell"] && ( 
          <tr>
            <td><strong>Spouse's Cell</strong></td>
            <td>{plan["Spouse's Cell"] || ""}</td>
          </tr>
          )} 

          {plan && plan["Spouse's Email Address"] && ( 
          <tr>
            <td><strong>Spouse's Email Address</strong></td>
            <td>{plan["Spouse's Email Address"] || ""}</td>
          </tr>
          )} 

          {plan && plan["Spouse's Date of Birth"] && ( 
          <tr>
            <td><strong>Spouse's Date of Birth</strong></td>
            <td>{plan["Spouse's Date of Birth"] || ""}</td>
          </tr>
          )} 

          {plan && plan["Address"] && ( 
          <tr>
            <td><strong>Address</strong></td>
            <td>{plan["Address"] || ""}</td>
          </tr>
          )} 

          {plan && plan["Address 1"] && ( 
          <tr>
            <td><strong>Address 1</strong></td>
            <td>{plan["Address 1"] || ""}</td>
          </tr>
          )}

          {plan && plan["Address 2"] && ( 
          <tr>
            <td><strong>Address 2</strong></td>
            <td>{plan["Address 2"] || ""}</td>
          </tr>
          )} 

          {plan && plan["Another Name Value"] && ( 
            <>

          <tr>
            <td><strong>Another Name Value</strong></td>
            <td>{plan["Another Name Value"] }</td>
          </tr>
          </>
           )} 

          {plan && plan["City"] && ( 
          <tr>
            <td><strong>City</strong></td>
            <td>{plan["City"] || ""}</td>
          </tr>
           )}

          {plan && plan["State"] && ( 
          <tr>
            <td><strong>State</strong></td>
            <td>{plan["State"] || ""}</td>
          </tr>
          )}

          {plan && plan["Zipcode"] && ( 
          <tr>
            <td><strong>Zipcode</strong></td>
            <td>{plan["Zipcode"] || ""}</td>
          </tr>
          )}

          <tr>
            <td><strong>US Citizen</strong></td>
            <td>{plan["US Citizen"] ? "Yes" : "No"}</td>
          </tr>

          {plan && plan["Nickname"] && ( 
          <tr>
            <td><strong>Nickname</strong></td>
            <td>{plan["Nickname"] || ""}</td>
          </tr>
          )}

          {plan && plan["Marital History"] && (  
          <tr>
            <td><strong>Marital History</strong></td>
            <td>{plan["Marital History"] || ""}</td>
          </tr> 
          )}

          {plan && plan["Fax formated"] && (  
          <tr>
            <td><strong>Is Approved</strong></td>
            <td>{plan["Is Approved"] ? "Yes" : "No"}</td>
          </tr>
          )} 


                  </tbody>
                </table>
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default AdminPlanDetail;
