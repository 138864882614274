import React from 'react';

const Location = () => {

  const addresses = [
    {
      name: 'Boston',
      address: '1 Federal Street',
      city: ['Boston, MA 02210'],
      image: '/images/boston.jpg',
      mapLInk: 'https://maps.app.goo.gl/ze6vWp8aPvEx22679'
    },
    {
      name: 'Norwell',
      address: '99 Derby Street',
      city: ['Derby Corporate Center', 'Hingham, MA 02043'],
      image: '/images/norwell.jpg',
      mapLInk: 'https://maps.app.goo.gl/Qc7bVVz8AorMJ6a99'
    },
    // {
    //   name: 'South Shore',
    //   address: '600 Cordwainer Drive',
    //   city: 'Norwell, MA 02061',
    //   image: './images/office-3.png',
    //   mapLInk: 'https://www.google.com/maps/dir//600+Cordwainer+Dr+Suite+201,+Norwell,+MA+02061/@42.163817,-70.9647255,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e49e226ceaaaab:0x599ae2955cd3f92d!2m2!1d-70.8823247!2d42.1638465?entry=ttu'
    // },
    // {
    //   name: 'MetroWest',
    //   address: '93 Worcester Street',
    //   city: 'Wellesley, MA 02481',
    //   image: './images/office-4.png',
    //   mapLInk: 'https://www.google.com/maps/dir//93+Worcester+St,+Wellesley,+MA+02481/@42.3178918,-71.3197985,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e38232f16b63d5:0xac76af70439a841c!2m2!1d-71.2373977!2d42.3179213?entry=ttu'
    // }
  ];

  const handleButtonClick = (address) => {
    const googleMapsUrl = address;
    window.open(googleMapsUrl, '_blank');
  };

  return (
    <div className="tab-pane fade" id="nav-locations" role="tabpanel" aria-labelledby="nav-locations-tab">
    <div className='rightSec p-md-5 p-3'>
      <div className='row margin_50px'>
        {addresses.map((address, index) => (
          <div className="col-md-6" key={index}>
            <div className="w-100">
              <img src={address.image} className='w-100' alt="..." />
            </div>
            <div className='d-md-flex w-100 justify-content-between text-white py-3 align-items-center'>
            <p>
              <b className='mb-2 d-inline-block fs-23 fw-bold'>{address.name}</b> <br />
              {address.address} <br />
              {address.city.map((line, i) => (
                    <span key={i}>
                      {line}
                      <br />
                    </span>
                  ))}
              </p>
              <div className='' id="homepage-button">
              <button type="button" className="btn btn-primary  px-5"  onClick={() => handleButtonClick(address.mapLInk)}>Directions</button>
            </div>
            </div>
          </div>
        ))}

      </div>
    </div>
  </div>
  );

};

export default Location;