import './Header.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Header() {

  const handleLoginClick = () => {
    window.open('https://pywl.sharefile.com/', '_blank');
  };

  const handleScheduleAppointmentClick = () => {
    window.open('https://protectingyourwealthlawpc.fullslate.com', '_blank');
  };

  const location = useLocation();
  const pathname = location.pathname;

  const isHeaderVisible = !(pathname === '/user/login' || pathname === '/admin/login');

  return (
    <>
      <header className="header mb-2 py-3">
        <div className='container'>
          <div className='row align-items-end'>
            <div className='col-md-6'>
            <Link to="/">
              <img src='./../images/logo.png' className='img-fluid w-100' alt='' />
            </Link>
            </div>
            
            <div className='col-md-6 text-center h-100 ps-md-5'>
             
              <p>We’re excited to announce that we are
                now part of <br /> Conn Kavanaugh LLP.
              </p>

              {isHeaderVisible &&
              <div className='d-flex w-100 gap-3' id="homepage-button">
                <button type="button" className="btn btn-primary w-100" onClick={handleLoginClick}>Client Login</button>
                <button type="button" className="btn btn-primary w-100" onClick={handleScheduleAppointmentClick}>Schedule Appointment</button>
              </div>
               }
            </div>

          </div>
        </div>
      </header>
    </>
  );
}

export default Header;