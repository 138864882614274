import React from 'react';
import './HomePage.css';
import Location from '../../components/MainPage/Location/Location';
import Attorney from '../../components/MainPage/Attorney/Attorney';
import Practice from '../../components/MainPage/Practice/Practice';
import NavigationTabs from '../../components/MainPage/NavigationTabs/NavigationTabs';
import Profile from '../../components/MainPage/Profile/Profile';
import Footer from '../../components/MainPage/Footer/Footer'
import Header from '../../components/MainPage/Header/Header'

const HomePage = () => {

  return (
    <div className='main-content'>
      <div className='container'>
        <Header />
        <div className='row'>
          <NavigationTabs />
          <div className='col-md-12'>
            <div className="tab-content" id="nav-tabContent">
              <Profile />
              <Practice />
              <Attorney />
              <Location />

              <div className="tab-pane fade" id="nav-reviews" role="tabpanel" aria-labelledby="nav-reviews-tab">...</div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div >
  );

};

export default HomePage;
