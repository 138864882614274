import React, { useEffect, useState } from 'react';
import './AdminPlan.css';
// import { useParams } from 'react-router-dom';
import { getApproveNonApproveTotal } from '../../../services/adminService';
import { useNavigate } from 'react-router-dom';

const AdminPlan = () => {
  const navigate = useNavigate();
  // const { planId } = useParams();
  const [plan, setPlan] = useState({});

  useEffect(() => {
    const fetchPlanById = async () => {
      try {
        const data = await getApproveNonApproveTotal();
        setPlan(data);
      } catch (error) {
        console.error('Error fetching plan details:', error);
      }
    };

    fetchPlanById();
  }, []);

  const handleApproveRedirect = () => {
    navigate(`/admin/plan/approve`);
  };

  const handleNonApproveRedirect = () => {
    navigate(`/admin/plan/non-approve`);
  };

  const handleAllRedirect = () => {
    navigate(`/admin/plan/all`);
  };

  return (
    <>

      <div className='plan-list'>
        <div className='container'>
          <div className='row justify-content-between align-items-end'>
            <div className='col-md-12'>
              <h1 className='my-5 text-white'>Contact Information Update</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
            <div className="d-flex align-items-center bgDark p-4">
              <div className="flex-shrink-0">
                <img src="../images/approve-load.png" alt="..." />
              </div>
              <div className="flex-grow-1 ms-4">
                <h6 className='text-white'>Approved Plans</h6>
                <h2 className='text-white'>{plan.approvedCount}</h2>
                <button className="btn btn-primary rounded-2 text-white px-5 mt-2" onClick={() => handleApproveRedirect()}>View Now</button>
              </div>
            </div>
            </div>
            <div className='col-md-4'>
            <div className="d-flex align-items-center bgDark p-4">
              <div className="flex-shrink-0">
                <img src="../images/not-approve.png" alt="..." />
              </div>
              <div className="flex-grow-1 ms-4">
                <h6 className='text-white'>Not Approved Plans</h6>
                <h2 className='text-white'>{plan.nonApprovedCount}</h2>
                <button className="btn btn-primary rounded-2 text-white px-5 mt-2"  onClick={() => handleNonApproveRedirect()}>View Now</button>
              </div>
            </div>
            </div>
            <div className='col-md-4'>
            <div className="d-flex align-items-center bgDark p-4">
              <div className="flex-shrink-0">
                <img src="../images/all-plan.png" alt="..." />
              </div>
              <div className="flex-grow-1 ms-4">
                <h6 className='text-white'>All Plans</h6>
                <h2 className='text-white'>{plan.totalCount}</h2>
                <button className="btn btn-primary rounded-2 text-white px-5 mt-2"  onClick={() => handleAllRedirect()}>View Now</button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );

};

export default AdminPlan;
