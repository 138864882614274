import React, { useEffect, useState } from 'react';
import './AddPlan.css';
import { useParams } from 'react-router-dom';
import { getPlanById, createPlanByName, getPlanParent } from '../../../services/planListService';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MessageDialog from '../../../components/Modal/MessageDialog';
import Modal from 'react-modal'; 
import Constants from '../../../utilities/constants'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SucessValidate from '../../../components/Modal/SucessValidate';

const AddPlan = () => {

  const baseURL = Constants.BASE_URL;

  const { id } = useParams();
  const navigate = useNavigate();
  const [planName, setPlanName] = useState(null);
  const [subRelationshipOptions, setSubRelationshipOptions] = useState([]);
  const [showParentDropdown, setShowParentDropdown] = useState(false);
  const [fullNames, setFirstNames] = useState([]); 
  const [zipResponseSuccess, setZipResponseSuccess] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isError, setIsError] = useState(false); 

  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);

  const [isCharityVisible, setIsCharityVisible] = useState(true);
  const [isContactInformationVisible, setContactInformationVisible] = useState(true);
  const [isAddressVisible, setAddressVisible] = useState(true);
  const [isRelationshipVisible, setRelationshipVisible] = useState(true);
  const [isMaritalStatusVisible, setMaritalStatusVisible] = useState(true);

  const [isDeceasedDeathVisible, setDeceasedDeathVisible] = useState(false);
  const [isEstrangedDeathVisible, setEstrangedDeathVisible] = useState(false);
  const [isRemoveDeathVisible, setRemoveDeathVisible] = useState(false);

  const initialFormData = {
    "First Name": "",
    "Last Name": "",
    "M": "",
    "Email": "",
    "Nickname":"",
    "Relationship Type": "",
    "Relationship Order": "",
    "Parent": "",
    "contactStatus": "",
    "Mobile": "",
    "Date of Birth": "",
    "YOB": "",
    "US Citizen": "",
    "Marital Status": "",
    "Address 1": "",
    "City": "",
    "Another Name Value": "",
    "State": "",
    "Zipcode": "",
    "Charity Name": ""
  };

  const fieldsToClear = [
    "First Name", "Last Name", "M", "Email", "Nickname", "Relationship Type", 
    "Relationship Order", "Parent", "Mobile", "YOB", "US Citizen", 
    "Marital Status", "Address 1", "City", "Another Name Value", "State", 
    "Zipcode", "Charity Name"
  ];

  // const [formData, setFormData] = useState( initialFormData );
  
  const [formData, setFormData] = useState({
    ...initialFormData,
    contactStatus: 'Adult'
  });

  

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleParentChange = (e) => {
    const { name, value } = e.target;
  
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleRelationshipChange = async (event) => {
    const selectedRelationship = event.target.value;
    // alert(selectedRelationship)

    try {
      const data = await getPlanParent( planName, selectedRelationship  );
      // console.log(data,"fullNamesfullNamesfullNamesfullNamesfullNamesfullNamesfullNamesfullNamesfullNamesfullNames") 
      const fullNames = data.map(item => `${item["First Name"]} ${item["Last Name"]}`);
      setFirstNames(fullNames);

    } catch (error) {
      console.error('Error fetching plan details:', error);
    }
    
    // setFormData(prevData => ({
    //   ...prevData,
    //   "Relationship Type": selectedRelationship
    // }));

    setFormData(prevData => ({
      ...prevData,
      "Relationship Type": selectedRelationship,
      // Clear the parent value if the selected relationship type doesn't allow for selecting a parent
      "Parent": ['Child', 'Grandchild', 'Niece/Nephew', 'Primary Contact'].includes(selectedRelationship) ? prevData["Parent"] : ""
    }));

    let options = [];
    

    if (selectedRelationship === 'Parent') {
      setShowParentDropdown(false);
      options = ['father', 'mother'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    } else if (selectedRelationship === 'Spouse') {
      setShowParentDropdown(false);
      options = ['husband', 'wife', 'ex-husband', 'ex-wife', 'deceased-husband', 'deceased-wife', 'deceased spouse'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    } else if (selectedRelationship === 'Child') {
      setShowParentDropdown(true);
      options = ['daughter', 'son', 'deceased child',	'estranged child', 'adopted child',	'stepchild'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Grandchild') {
      setShowParentDropdown(true);
      options = ['grandson', 'granddaughter'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Sibling') {
      setShowParentDropdown(false);
      options = ['brother', 'sister', 'step brother', 'step sister', 'brother-in law', 'sister-in law'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Niece/Nephew') {
      setShowParentDropdown(true);
      options = ['niece', 'nephew'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Cousin') {
      setShowParentDropdown(false);
      options = ['cousin'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Friend') {
      setShowParentDropdown(false);
      options = ['friend'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Professionals') {
      setShowParentDropdown(false);
      options = ['accountant', 'financial advisor', 'attorney'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Primary Contact') {
      setShowParentDropdown(false);
      options = ['Husband', 'Wife', 'Single Client'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }
    else if (selectedRelationship === 'Charity') {
      setShowParentDropdown(false);
      options = ['charity'].map((subRelation) => (
        <option key={subRelation} value={subRelation}>{subRelation}</option>
      ));
    }

    setSubRelationshipOptions(options);
  };

  useEffect(() => {
    const fetchPlanById = async () => {
      try {
        const data = await getPlanById(id);
        const planNameFromData = data ? data["Plan Name"] : null;
        setPlanName(planNameFromData); 
        // setPlan(data);

        setFormData(prevData => ({
          ...prevData,
          "Plan Name": planNameFromData
        }));

        // console.log(data, "datadata")
      } catch (error) {
        console.error('Error fetching plan details:', error);
      }
    };

    fetchPlanById();
  }, [id]);

  const contactStatusOptions = [
    { value: "", label: "Select Status" },
    { value: "Adult", label: "Active (Adult)" },
    { value: "Minor Child", label: "Active (Minor Child)" },
    { value: "Incapacitated", label: "Incapacitated" },
    { value: "Disabled", label: "Disabled" },
    { value: "Charity", label: "Charity" },
    { value: "Deceased", label: "Deceased" },
    { value: "Estranged", label: "Estranged" },
    { value: "Remove", label: "Remove" }
];

const handleUSCitizenChange = (value) => {
  setFormData(prevData => ({
    ...prevData,
    "US Citizen": value
  }));
};

  const createPlan = async () => {
    try {
    
    if (!formData["contactStatus"]) {
      setDialogTitle('Error');
      setDialogMessage('Contact Status is required.');
      setIsError(true); 
      setIsDialogVisible(true);
      return;

    }

    if (formData["contactStatus"] === "Adult") {
      const requiredFields = ["First Name", "Last Name", "Relationship Type", "Relationship Order", "Mobile", "Email", "YOB", "US Citizen", "Marital Status", "Address 1", "City", "State", "Zipcode"];
      for (const field of requiredFields) {
        if (!formData[field]) {
          setDialogTitle('Error');
          setDialogMessage(`${field.replace('_', ' ')} is required.`);
          setIsError(true);
          setIsDialogVisible(true);
          return;
        }
      }
    }

    if (formData["contactStatus"] === "Minor Child") {
      const requiredFields = ["First Name", "Last Name", "Relationship Type", "Relationship Order", "YOB", "US Citizen", "Address 1", "City", "State", "Zipcode"];
      for (const field of requiredFields) {
        if (!formData[field]) {
          setDialogTitle('Error');
          setDialogMessage(`${field.replace('_', ' ')} is required.`);
          setIsError(true);
          setIsDialogVisible(true);
          return;
        }
      }
    }

    if (formData["contactStatus"] === "Incapacitated") {
      const requiredFields = ["First Name", "Last Name", "Relationship Type", "Relationship Order", "YOB", "US Citizen", "Marital Status", "Address 1", "City", "State", "Zipcode"];
      for (const field of requiredFields) {
        if (!formData[field]) {
          setDialogTitle('Error');
          setDialogMessage(`${field.replace('_', ' ')} is required.`);
          setIsError(true);
          setIsDialogVisible(true);
          return;
        }
      }
    }

    if (formData["contactStatus"] === "Disabled") {
      const requiredFields = ["First Name", "Last Name", "Relationship Type", "Relationship Order", "YOB", "US Citizen", "Marital Status", "Address 1", "City", "State", "Zipcode"];
      for (const field of requiredFields) {
        if (!formData[field]) {
          setDialogTitle('Error');
          setDialogMessage(`${field.replace('_', ' ')} is required.`);
          setIsError(true);
          setIsDialogVisible(true);
          return;
        }
      }
    }

      if (formData["contactStatus"] === "Charity") {
        const requiredFields = ["Charity Name", "Mobile", "Email", "Address 1", "City", "State", "Zipcode"];
        for (const field of requiredFields) {
          if (!formData[field]) {
            setDialogTitle('Error');
            setDialogMessage(`${field.replace('_', ' ')} is required.`);
            setIsError(true);
            setIsDialogVisible(true);
            return;
          }
        }
      }

    if (formData["contactStatus"] === "Deceased") {
      const requiredFields = ["First Name", "Last Name", "Date of Death", "Place of Death", "Residency of Death"];
      for (const field of requiredFields) {
        if (!formData[field]) {
          setDialogTitle('Error');
          setDialogMessage(`${field.replace('_', ' ')} is required.`);
          setIsError(true);
          setIsDialogVisible(true);
          return;
        }
      }
    }

    if (formData["contactStatus"] === "Estranged") {
      const requiredFields = ["First Name", "Last Name", "Date of Estranged"];
      for (const field of requiredFields) {
        if (!formData[field]) {
          setDialogTitle('Error');
          setDialogMessage(`${field.replace('_', ' ')} is required.`);
          setIsError(true);
          setIsDialogVisible(true);
          return;
        }
      }
    }

    if (formData["contactStatus"] === "Remove") {
      const requiredFields = ["First Name", "Last Name", "Date of Remove"];
      for (const field of requiredFields) {
        if (!formData[field]) {
          setDialogTitle('Error');
          setDialogMessage(`${field.replace('_', ' ')} is required.`);
          setIsError(true);
          setIsDialogVisible(true);
          return;
        }
      }
    }

    // console.log("qqqqqqqqqqq",formData["US Citizen"])

    // if (formData["US Citizen"] === "") {
    //   formData["US Citizen"] = true;
    // }

        if (
          (formData["contactStatus"] === "Adult") || 
          (formData["contactStatus"] === "Minor Child") || 
          (formData["contactStatus"] === "Incapacitated") ||
          (formData["contactStatus"] === "Disabled") ||
          (formData["contactStatus"] === "Charity")
         ) {
          if(zipResponseSuccess){
            // alert("Aaa")
            const isResult = await createPlanByName(formData);
            // console.log(isResult,"isResult")

          if (isResult.success) {
            if(isResult.data.isVerified){
              setErrorMessage('You have successfully updated your contacts. Within the next few days, you will receive a letter by DocuSign to authorize me to bring your records to Conn Kavanaugh LLP. Once completed, we can continue to work together and I can provide you with improved service and a full range of legal expertise.');
              setIsSuccessVisible(true);
              setIsDialogVisible(false);
            }
            else{
              setDialogTitle('Success');
              setIsError(false); 
              setDialogMessage('Plan Added successfully');
              setIsDialogVisible(true);
              setIsSuccessVisible(false);
            }       
          }
        }else{
          setDialogTitle('Error');
          setIsError(true);
          setDialogMessage('Invalid ZIP code or ZIP code not found.');
          setIsDialogVisible(true);
          return false;
        }
      }else{
        const isResult = await createPlanByName(formData);
        if (isResult.success) {
          if(isResult.data.isVerified){
            setErrorMessage('You have successfully updated your contacts. Within the next few days, you will receive a letter by DocuSign to authorize me to bring your records to Conn Kavanaugh LLP. Once completed, we can continue to work together and I can provide you with improved service and a full range of legal expertise.');
            setIsSuccessVisible(true);
            setIsDialogVisible(false);
          }
          else{
            setDialogTitle('Success');
            setIsError(false); 
            setDialogMessage('Plan Added successfully');
            setIsDialogVisible(true);
            setIsSuccessVisible(false);
          }       
        }
      }

    } catch (error) {
      console.error('Error creating plan:', error);
    }
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

     // Update form data
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));

    // alert(name)
    // alert(value)
    if (name === "contactStatus") {

      const updatedFormData = { ...formData };
      
       // Clear only the relevant fields
      fieldsToClear.forEach(field => {
        updatedFormData[field] = "";
      });

      // Update formData with cleared fields and the new contactStatus value
      updatedFormData[name] = value;
      setFormData(updatedFormData);

      if (value === "Adult"){
        setIsCharityVisible(true);
        setContactInformationVisible(true)
        setAddressVisible(true)
        setRelationshipVisible(true)
        setMaritalStatusVisible(true)

        setRemoveDeathVisible(false)
        setEstrangedDeathVisible(false)
        setDeceasedDeathVisible(false)
      }
      else if (value === "Minor Child"){
        setIsCharityVisible(true);
        setRelationshipVisible(true)
        setContactInformationVisible(false)
        setAddressVisible(true)

        setMaritalStatusVisible(false)

        setRemoveDeathVisible(false)
        setEstrangedDeathVisible(false)
        setDeceasedDeathVisible(false)
      }
      else if (value === "Incapacitated"){
        setContactInformationVisible(false)
        setRelationshipVisible(true)
        setAddressVisible(true)
        setIsCharityVisible(true);
        setMaritalStatusVisible(true)

        setRemoveDeathVisible(false)
        setEstrangedDeathVisible(false)
        setDeceasedDeathVisible(false)
      }
      else if (value === "Disabled"){
        setContactInformationVisible(false)
        setRelationshipVisible(true)
        setIsCharityVisible(true);
        setAddressVisible(true)
        setMaritalStatusVisible(true)

        setRemoveDeathVisible(false)
        setEstrangedDeathVisible(false)
        setDeceasedDeathVisible(false)
      }
      else if (value === "Deceased"){
        setIsCharityVisible(true);
        setContactInformationVisible(false)
        setAddressVisible(false)
        setRelationshipVisible(false)

        setDeceasedDeathVisible(true)
        setRemoveDeathVisible(false)
        setEstrangedDeathVisible(false)

      }
      else if (value === "Charity"){
        setIsCharityVisible(false);
        setContactInformationVisible(true)
        setAddressVisible(true)
        setRelationshipVisible(false)

        setRemoveDeathVisible(false)
        setEstrangedDeathVisible(false)
        setDeceasedDeathVisible(false)
      }
      else if (value === "Estranged"){
        setIsCharityVisible(true);
        setContactInformationVisible(false)
        setAddressVisible(false)
        setRelationshipVisible(false)

        setEstrangedDeathVisible(true)
        setRemoveDeathVisible(false)
        setDeceasedDeathVisible(false)
      }
      else if (value === "Remove"){
        setIsCharityVisible(true);
        setContactInformationVisible(false)
        setAddressVisible(false)
        setRelationshipVisible(false)

        setRemoveDeathVisible(true)
        setEstrangedDeathVisible(false)
        setDeceasedDeathVisible(false)

      }
      else{
        // setIsFirstMiddelLastSuffixKnownNickVisible(true);
      }
    }

    if (name === "Zipcode") {
    if (value.length === 5) {
      // if (value.length === 5 || value.length === 3) {
      try {
        const response = await fetch(`${baseURL}/zip?Zipcode=${value}`);
        
        // Check if the API call was successful
        if (response.ok) {
          const responseData = await response.json();
          // Check if the response indicates success or failure
          if (responseData.success) {
            // Set zipResponseSuccess state to true if Zip code was found
            setZipResponseSuccess(true);
           
          } else {
            // Set zipResponseSuccess state to false if Zip code was not found
            setZipResponseSuccess(false);
            // Display an alert if Zip code was not found
            alert(responseData.message);
          }
        } else if (response.status === 404) {
          // Set zipResponseSuccess state to false if Zip code was not found
          setZipResponseSuccess(false);
          // Display an alert if Zip code was not found

          setDialogTitle('Error');
          setIsError(true);
          setDialogMessage('Zip code not found');
          setIsDialogVisible(true);
          return ;

        } else {
          // Set zipResponseSuccess state to false for other status codes
          setZipResponseSuccess(false);
          // Handle other status codes if needed
          console.log("Unexpected status code:", response.status);
        }
      } catch (error) {
        // Set zipResponseSuccess state to false if there was an error
        setZipResponseSuccess(false);
        // Handle error
        console.error("Error fetching data:", error);
      }
    } else {
      // Reset zipResponseSuccess state if the Zip code length is not 5
      setZipResponseSuccess(false);
    }
    }

    if (name === "Mobile" && /^\d+$/.test(value)) {

      const phoneNumber = value.replace(/\D/g, '');
      const formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    
      setFormData(prevData => ({
        ...prevData,
        [name]: formattedPhoneNumber
      }));
    } 

    else if (name === "Relationship Order") {
      // alert(value); // Example: Alert the selected sub-relationship value
      // console.log( planName, "planName")
      // console.log( value, "value")

      setFormData(prevData => ({
        ...prevData,
        "Relationship Order": value
      }));

      // try {
      //   const data = await getPlanParent( planName, value  );

      //   const fullNames = data.map(item => `${item["First Name"]} ${item["Last Name"]}`);
      //   setFirstNames(fullNames);

      //   // const planNameFromData = data ? data["Plan Name"] : null;
      //   // setPlanName(planNameFromData, value); 
      //   // setPlan(data);

      //   // setFormData(prevData => ({
      //   //   ...prevData,
      //   //   "Plan Name": planNameFromData
      //   // }));

      //   // console.log(data, "datadata Relationship Ordersssssssss")
      // } catch (error) {
      //   console.error('Error fetching plan details:', error);
      // }

    }
    
    else if (name === "YOB") {

        // setFormData(prevData => ({
        //   ...prevData,
        //   [name]: formattedDate
        // }));
    } 
    else {

      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleDateChange = (date, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: date,
    });
  };
  
  const handleMaritalStatusChange = (value) => {
    setFormData(prevData => ({
      ...prevData,
      "Marital Status": value
    }));
  };

  const handleOk = () => {
    setIsSuccessVisible(false);
    navigate('/user/planList');
  };

  return (
    <>

      <div className='plan-list'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h1 className='my-5 text-white'>Contact Information Update</h1>
            </div>    
            <div className='col-md-12'>
              <div className='d-md-flex align-items-end'>
                <div className="col-md-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label text-white">Name</label>
                  <input
                    type="text"
                    className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                    disabled  value={planName ? planName : ''}
                  />
                </div>
                <div className='col-md-auto ms-5'>
                <button 
                  className="btn btn-primary rounded-2 text-white px-5 mt-2" 
                  onClick={handleBackClick}>
                    Back to Summary
                  </button>
                  </div>
              </div>
            </div>
          </div>
          <div className='bg-darkInfo p-5 mt-4 text-white'>
            
            {/* <div className='col-md-6 mb-4'>
              <h6 className='text-white'>Name as listed on driver’s license or government id</h6>
            </div> */}

            <div className='col-md-12 mb-4'>
              <div className='row m-0 justify-content-between align-items-center'>
            <div className='col-md-4 mb-4'>
              {/* <h6 className='text-white name-legal'>
                Name as listed on driver’s <br/> license or government id
              </h6> */}
              <div class="name-legal">
                    <div class="name-legal-inner">
                      <h6 className='text-white' style={{fontSize:"18px", textAlign:"center"}}>Enter name as listed on driver's <br/> 
                        license or government id
                      </h6>
                    </div>
                  </div>
            </div>
            <div className='col-md-5 mb-4 contact-status'>
              <div className='d-flex align-items-center contact-status-inner'>
                  <label htmlFor="exampleFormControlInput1" className="form-label text-white col-md-auto me-3">Confirm Contact Status:</label>
                    <select className="form-select rounded-1 inp px-3 mb-0 w-100 selectCustom bgDarred" 
                    name="contactStatus" 
                    value={formData.contactStatus}
                    onChange={handleInputChange}
                    >
                     {contactStatusOptions.map(option => (
                        <option 
                          key={option.value} 
                          value={option.value}>
                            {option.label}
                        </option>
                    ))}
                    </select>
            </div>
            </div>
            </div>
            </div>
          
          {!isCharityVisible && (
          <div className='Charity'>
            <div className='row'>
              <div className='col-md-6'>
              <div className='row mb-3'>
                  <div className='col-md-12 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Charity Name *</label>
                    <input type="text" placeholder='Enter Charity Name'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      id="otherNameInput"
                      name="Charity Name"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              </div>
          </div>
          )}

          {isCharityVisible && (
          <div className='peronalDetail'>
            <div className='row'>
                  <div className='col-md-3 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">First Name *</label>
                    <input type="text" placeholder='Enter first name'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="First Name" onChange={handleInputChange}
                      value={formData["First Name"]}
                    />
                  </div>
                  <div className='col-md-3 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Middle Initial</label>
                    <input type="text" placeholder='Enter middle initial'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="M" onChange={handleInputChange}
                      value={formData["M"]}
                    />
                  </div>
                  <div className='col-md-3 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Last Name *</label>
                    <input type="text" placeholder='Enter last name'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Last Name" onChange={handleInputChange}
                      value={formData["Last Name"]}
                    />
                  </div>
                  <div className='col-md-3 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Suffix</label>
                    <select className="form-select rounded-1 inp px-3 mb-0 w-100 selectCustom" name="Suffix" onChange={handleInputChange}>
                      <option value="">Select</option>
                      <option value="Sr.">Sr.</option>
                      <option value="Jr.">Jr.</option>
                    </select>
                  </div>
                  <div className='col-md-6 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Also known as</label>
                    <input type="text" placeholder='Also known as Enter name you are also known as'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      id="otherNameInput"
                      name="Another Name Value"
                      onChange={handleInputChange}
                      value={formData["Another Name Value"]}
                    />
                  </div>
                  <div className='col-md-6 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Nickname</label>
                    <input type="text" placeholder='Enter nickname'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Nickname"
                      onChange={handleInputChange}
                      value={formData["Nickname"]}
                    />
                  </div>
            </div>
          </div>
           )}
          
          {(isCharityVisible && isRelationshipVisible) && (
          <div className='relationshipDetail'>
            <div className='row'>
              <div className='col-md-3 mb-3'>
                    <label htmlFor="relationship" className="form-label text-white">Relationship *</label>
                    <select className="form-select rounded-1 inp px-3 mb-0 w-100 selectCustom" 
                    name="Relationship Type" 
                    value={formData["Relationship Type"]}
                    onChange={handleRelationshipChange}>
                      <option value="">Select</option>
                      <option value="Parent">Parent</option>
                      <option value="Spouse">Spouse</option>
                      <option value="Grandchild">Grandchild</option>
                      <option value="Child">Child</option>
                      <option value="Sibling">Sibling</option>
                      <option value="Niece/Nephew">Niece/Nephew</option>
                      <option value="Cousin">Cousin</option>
                      <option value="Friend">Friend</option>
                      <option value="Professionals">Professionals</option>
                      <option value="Primary Contact">Primary Contact</option>
                      {/* <option value="Charity">Charity</option> */}
                    </select>
              </div>

              <div className='col-md-3 mb-3'>
                        <label htmlFor="subRelationship" className="form-label text-white">Sub Relationship *</label>
                        <select className="form-select rounded-1 inp px-3 mb-0 w-100 selectCustom" 
                        name="Relationship Order" 
                        onChange={handleInputChange}
                        value={formData["Relationship Order"]}
                        >
                          <option value="">Select</option>
                          {subRelationshipOptions}
                        </select>
              </div>

              {showParentDropdown && (
              <div className='col-md-6 mb-3'>
                    <label htmlFor="relationship" className="form-label text-white">Parent *</label>
                    <select className="form-select rounded-1 inp px-3 mb-0 w-100 selectCustom" 
                    name="Parent" 
                    onChange={handleParentChange}
                    value={formData["Parent"]}
                    >
                      <option value="">Select</option>
                      {fullNames.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                      <option value="N/A">N/A</option> 
                    </select>
              </div>
              )}
 
            </div>
          </div>
          )}

          {isContactInformationVisible && (
          <div className="contact-information mb-3">
            <div className='row'>
            <div className='col-md-6'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Enter phone number *</label>
                    <input type="text" placeholder='(###) ###-####'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Mobile"
                      onChange={handleInputChange}
                      maxLength={14}
                      value={formData["Mobile"]}
                    />
            </div>


            <div className='col-md-6'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Email Address *</label>
                    <input type="text" placeholder='Enter email address'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Email" 
                      onChange={handleInputChange}
                      value={formData["Email"]}
                    />
            </div>

            </div>
          </div>
          )}

          {(isCharityVisible && isRelationshipVisible) && (
          <div className='demographic-information mb-3'>
          <div className='row'>

                <div className='col-md-3'>
                          <label htmlFor="exampleFormControlInput1" className="form-label text-white">Enter Year of Birth *</label>

                          <input type="text" placeholder='####'
                            className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                            name="YOB" 
                            onChange={handleInputChange}
                            placeholderText="####"
                            value={formData["YOB"]}
                            maxLength={4}
                            onKeyPress={(e) => {
                              const onlyDigits = /[0-9]/;
                              const key = e.key;
                              if (!onlyDigits.test(key)) {
                                e.preventDefault();
                              }
                            }}
                          />

                            {/* <DatePicker
                              selected={formData['YOB']}
                              onChange={(date) => handleDateChange(date, 'YOB')}
                              dateFormat="MM-dd-yyyy"
                              placeholderText="Select Date"
                              className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                              name="YOB"
                              isClearable
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={15}
                              dropdownMode="select"
                              onKeyDown={(e) => e.preventDefault()}
                              autoComplete="off" 
                          /> */}
                </div>

                  <div className='col-md-3 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">U.S Citizen? *</label>
                    <div className='d-flex gap-3'>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="US Citizen"
                          id="noCitizen"
                          autoComplete="off"
                          onChange={() => handleUSCitizenChange('Yes')}
                          checked={formData["US Citizen"] === 'Yes'}
                        />
                        <label className="form-check-label" htmlFor="noCitizen">
                          Yes
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="US Citizen"
                          id="noCitizen"
                          autoComplete="off"
                          onChange={() => handleUSCitizenChange('No')}
                          checked={formData["US Citizen"] === 'No'}
                        />
                        <label className="form-check-label" htmlFor="noCitizen">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  {isMaritalStatusVisible && (               
                  <div className='col-md-6 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Marital Status *</label>
                    <div className='d-flex gap-3'>

                      <div className='row'>
                        
                        <div className='col-md-3'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="Marital Status"
                              id="marriedStatus"
                              autoComplete="off"
                              onChange={() => handleMaritalStatusChange('Married')}
                              checked={formData["Marital Status"] === 'Married'}
                            />
                            <label className="form-check-label" htmlFor="marriedStatus">
                              Married
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="Marital Status"
                              id="marriedStatus"
                              autoComplete="off"
                              onChange={() => handleMaritalStatusChange('Widowed')}
                              checked={formData["Marital Status"] === 'Widowed'}
                            />
                            <label className="form-check-label" htmlFor="marriedStatus">
                              Widowed
                            </label>
                          </div>

                        </div>

                        <div className='col-md-3'>

                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="Marital Status"
                              id="marriedStatus"
                              autoComplete="off"
                              onChange={() => handleMaritalStatusChange('Divorced')}
                              checked={formData["Marital Status"] === 'Divorced'}
                            />
                            <label className="form-check-label" htmlFor="marriedStatus">
                              Divorced
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="Marital Status"
                              id="marriedStatus"
                              autoComplete="off"
                              onChange={() => handleMaritalStatusChange('Single')}
                              checked={formData["Marital Status"] === 'Single'}
                            />
                            <label className="form-check-label" htmlFor="marriedStatus">
                              Single
                            </label>
                          </div>

                          
                        </div>

                        <div className='col-md-3'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="Marital Status"
                              id="separatedStatus"
                              autoComplete="off"
                              onChange={() => handleMaritalStatusChange('Separated')}
                              checked={formData["Marital Status"] === 'Separated'}
                            />
                            <label className="form-check-label" htmlFor="separatedStatus">
                            Separated
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="Marital Status"
                              id="remarriedStatus"
                              autoComplete="off"
                              onChange={() => handleMaritalStatusChange('Remarried')}
                              checked={formData["Marital Status"] === 'Remarried'}
                            />
                            <label className="form-check-label" htmlFor="remarriedStatus">
                            Remarried
                            </label>
                          </div>

                        </div>

                        <div className='col-md-3'>

                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="Marital Status"
                              id="lifePartnerStatus"
                              autoComplete="off"
                              onChange={() => handleMaritalStatusChange('Life Partner')}
                              checked={formData["Marital Status"] === 'Life Partner'}
                            />
                            <label className="form-check-label" htmlFor="lifePartnerStatus">
                            Life Partner
                            </label>
                          </div>

                        </div>

                      </div>

                    </div>
                  </div>
                  )}

            </div>
          </div>
           )}

          {/* // value={formatDateForInputField(formData["YOB"])} */}

          {/* {isCharityVisible && (
          <div className="death-information">
            <div className='row'>
            <div className='col-md-3 mb-3'>
                    <label htmlFor="relationship" className="form-label text-white">Date of Death</label>
                    <input type="date" 
                      placeholder=''
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Date of Death"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className='col-md-3 mb-3'>
                    <label htmlFor="subRelationship" className="form-label text-white">Place of Death</label>
                    <input type="text" placeholder='Enter Place'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Place of Death"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className='col-md-6 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Residency at Time of Death</label>
                    <input type="text" placeholder='Enter Residency'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Residency of Death" 
                      onChange={handleInputChange}
                    />
                  </div>

            </div>
          </div>
          )} */}

        {isDeceasedDeathVisible && (
          // <div className="death-information">
          //   <div className='row'>
          //   <div className='col-md-12 mb-3'>
          //           <label htmlFor="relationship" className="form-label text-white">Date of Deceased</label>
          //           <input type="date" 
          //             placeholder=''
          //             className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
          //             name="Date of Death"
          //             onChange={handleInputChange}
          //           />
          //         </div>
          //   </div>
          // </div>
          <div className="death-information">
            <div className='row'>
            <div className='col-md-3 mb-3'>
                    <label htmlFor="relationship" className="form-label text-white">Date of Death  *</label>
                       <DatePicker
                              selected={formData['Date of Death']}
                              onChange={(date) => handleDateChange(date, 'Date of Death')}
                              dateFormat="MM-dd-yyyy"
                              placeholderText="Select Date"
                              className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                              name="Date of Death"
                              isClearable
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={15}
                              dropdownMode="select"
                              onKeyDown={(e) => e.preventDefault()} 
                              autoComplete="off" 
                          />
                  </div>

                  <div className='col-md-3 mb-3'>
                    <label htmlFor="subRelationship" className="form-label text-white">Place of Death  *</label>
                    <input type="text" placeholder='Enter Place'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Place of Death"
                      onChange={handleInputChange}
                      value={formData["Place of Death"]}
                    />
                  </div>

                  <div className='col-md-6 mb-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Residency at Time of Death  * </label>
                    <input type="text" placeholder='Enter Residency'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Residency of Death" 
                      onChange={handleInputChange}
                      value={formData["Residency of Death"]}
                    />
                  </div>

            </div>
          </div>
          )}

        {isEstrangedDeathVisible && (
          <div className="death-information">
            <div className='row'>
            <div className='col-md-6 mb-3'>
                    <label htmlFor="relationship" className="form-label text-white">Date of Estrangement *</label>
                       <DatePicker
                             selected={formData['Date of Estranged']}
                             onChange={(date) => handleDateChange(date, 'Date of Estranged')}
                              dateFormat="MM-dd-yyyy"
                              placeholderText="Select Date"
                              className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                              name="Date of Estranged"
                              isClearable
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={15}
                              dropdownMode="select"
                              onKeyDown={(e) => e.preventDefault()}
                              autoComplete="off"
                          />
                  </div>
            </div>
          </div>
          )} 

          {isRemoveDeathVisible && (
          <div className="death-information">
            <div className='row'>
            <div className='col-md-6 mb-3'>
                    <label htmlFor="relationship" className="form-label text-white">Date of Remove  *</label>
                          <DatePicker
                              selected={formData['Date of Remove']}
                              onChange={(date) => handleDateChange(date, 'Date of Remove')}
                              dateFormat="MM-dd-yyyy"
                              placeholderText="Select Date"
                              className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                              name="Date of Remove"
                              isClearable
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={15}
                              dropdownMode="select"
                              onKeyDown={(e) => e.preventDefault()} 
                              autoComplete="off" 
                          />
                  </div>
            </div>
          </div>
          )} 
          
          {isAddressVisible && (
          <div className='address-information mb-3'>
          <div className='row'>
            
            <div className='col-md-6'>
                      <label htmlFor="exampleFormControlInput1" className="form-label text-white">Address Line 1 *</label>
                      <input type="text" placeholder='Enter address line 1'
                        className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                        name="Address 1"
                        onChange={handleInputChange}
                        value={formData["Address 1"]}
                      />
            </div>

            <div className='col-md-6'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Address Line 2</label>
                    <input type="text" placeholder='Enter address line 2'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Address 2" 
                      onChange={handleInputChange}
                      value={formData["Address 2"]}
                    />
            </div>

            <div className='col-md-6 mt-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">City *</label>
                    <input type="text" placeholder='Enter city'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="City"
                      onChange={handleInputChange}
                      value={formData["City"]}
                    />
            </div>

            <div className='col-md-3 mt-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">State *</label>
                    <input type="text" placeholder='Enter state'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="State"
                      onChange={handleInputChange}
                      value={formData["State"]}
                    />
                  </div>
                  <div className='col-md-3 mt-3'>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-white">Zip Code *</label>
                    <input type="text" placeholder='Enter zip code'
                      className="form-control rounded-1 text-white inp px-3 mb-0 w-100"
                      name="Zipcode"
                      onChange={handleInputChange}
                      maxLength={5} 
                      value={formData["Zipcode"]}
                    />
          </div>

          </div>
          </div>
           )} 
            <div className='col-md-12 text-end '>
              <button className="btn btn-primary rounded-2 text-white px-5 mt-2" onClick={() => createPlan()}>Press To Add</button>
            </div>

          </div>
        </div>
      </div>

      <Modal className="customModalContent" isOpen={isDialogVisible} onRequestClose={() => setIsDialogVisible(false)}>
        <MessageDialog 
        title={dialogTitle} 
        message={dialogMessage} 
        onOk={handleOk}
        isError={isError} 
        onClose={() => setIsDialogVisible(false)}
        />
      </Modal>

      <Modal className="customModalContent" isOpen={isSuccessVisible} onRequestClose={() => setIsSuccessVisible(false)}>
        <SucessValidate message={errorMessage} onClose={() => setIsSuccessVisible(false)}  onOk={handleOk} />
      </Modal>

    </>
  );

};

export default AddPlan;
