const Constants = {
  // BASE_URL: 'http://localhost:5002/api',
  BASE_URL: 'https://patriciashumaker.com/api',
  getTokens: () => ({
    userloggedIn: localStorage.getItem('userToken'),
    adminloggedIn: localStorage.getItem('adminToken')
}),
  formatPhoneNumber: (phoneNumber) => {
    if (!phoneNumber) return '';
    if (phoneNumber.length === 10) {
      return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
    }
    return phoneNumber;
  },
  formatDate: (dateString) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }
};

export default Constants;