import React from 'react';
import './loginHelp.css';


const LoginHelp = ({ message, onClose }) => {

    const openUrl = () => {
        window.open('https://calendly.com/hamburger66/30min', '_blank');
        onClose();
    };

    return (
        <div className="modal-dialog modal-dialog-centered loginHelp">
            <div className="modal-content px-5 py-2">
                <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
                    <button type="button" className="btn-close" onClick={onClose}>
                    </button>
                </div>
                <div className="modal-body pt-0 pb-5 px-4 text-center">
                <img src="../images/loginhelp.svg" alt="..." />
                    <h2 className='mb-3 text-white my-4'>Login Help</h2>
                    <div className="col-md-12 mb-2">
                        <div className="d-flex align-items-center">
                            <p className="mb-0 text-white">{message}</p>
                        </div>
                    </div>
                    <div className="gap-4 mt-5 d-flex justify-content-between">
                        <button type="button" className="btn btn-primary px-5 btnSize rounded-3" onClick={onClose}>
                            Try Again
                        </button>
                        <button type="button" 
                        className="btn btn-primary px-5 btnSize rounded-3"
                        onClick={openUrl}
                        >Login Help</button>
                    </div>

                </div>
            </div> 
        </div>
    );
};

export default LoginHelp;

