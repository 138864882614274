import React, { useEffect, useState } from 'react';
import './LoginAdmin.css';
import { useNavigate } from 'react-router-dom';
import { loginAdmin } from '../../../services/loginService';
import  Constants  from '../../../utilities/constants';
import { Navigate } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import MessageDialog from '../../../components/Modal/MessageDialog';
import Modal from 'react-modal';

const LoginAdmin = () => {

  const { adminloggedIn } = Constants.getTokens();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');


  const [errorMessage, setErrorMessage] = useState('');

  if(adminloggedIn){
    return <Navigate to={"/admin/plan"} />
  }

  const handleLogin = async (e) => {
    try {

      if (!email) {
        setDialogTitle('Error');
        setDialogMessage('Email is required.');
        setIsDialogVisible(true);
        return;

        // alert('Email is required');
        // return; 
      }

      if (!password) {

        setDialogTitle('Error');
        setDialogMessage('Password is required.');
        setIsDialogVisible(true);
        return;

      }
  
      const response = await loginAdmin({ email, password });
      // console.log(response);

      if (response && response.data) {
        const { token } = response.data;
        localStorage.setItem('adminToken', token);
        navigate('/admin/plan');
        window.location.reload();
      } else {

        setDialogTitle('Error');
        setDialogMessage('Email or Password is incorrect.');
        setIsDialogVisible(true);
        return;
        
      }

    } catch (error) {
      console.error('Error logging in:', error);
    }
  };
  return (
    <>
      <Header />
 <div className='main-wrapper p-3 h-100'>
        <div className='d-flex justify-content-center align-items-center h-100'>
          <div className='login p-5'>
            <div className='col-md-12 text-center'>
              {/* <img src="/images/logo.png" className='w-100 login' alt="Logo" /> */}
              <h1 className='my-3 text-white'>LOGIN - ADMIN</h1>
            </div>
            <div className='row gy-4'>
              <div className='col-md-12'>
                <label htmlFor="username" className="form-label text-white">Email</label>
                <input type="text" className="form-control rounded-1 inp px-3" id="username" value={email}
                      onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className='col-md-12'>
                <label htmlFor="username" className="form-label text-white">Password</label>
                <input type="text" className="form-control rounded-1 inp px-3" id="password" value={password}
                      onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className='col-md-12 text-center'>
                <button onClick={(e) => handleLogin(e)} className="btn btn-primary rounded-2 text-white px-5 mt-2">
                  Login Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="customModalContent" isOpen={isDialogVisible} onRequestClose={() => setIsDialogVisible(false)}>
        <MessageDialog title={dialogTitle} message={dialogMessage} isError={true} onClose={() => setIsDialogVisible(false)} />
      </Modal>

    </>
  );

};

export default LoginAdmin;
