import React, { useEffect, useState } from 'react';
import './AdminViewPlan.css';
import { useParams } from 'react-router-dom';
import { getPlanById } from '../../../services/adminService';
import { useNavigate } from 'react-router-dom';

const AdminViewPlan = () => {

  const { planId } = useParams();
  // console.log(planId,"planId")
  const navigate = useNavigate();
  const [plan, setPlan] = useState(null);
  const [PlanListName, setPlanListName] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedRelationship, setSelectedRelationship] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchPlanById = async () => {
      try {
        const data = await getPlanById(planId, currentPage, pageSize); 
        updatePlanData(data);
      } catch (error) {
        console.error('Error fetching plan details:', error);
      }
    };
  
    fetchPlanById();
  }, [planId, currentPage, pageSize]); 
  
  const updatePlanData = (data) => {
    setPlan(data.planList);
    setPlanListName(data.PlanListName);
    setTotalCount(data.totalCount);
    setApprovedCount(data.approvedCount);
    // console.log(data.planList, "datadata");
  };
  
  const isApprove = async (event) => {
    const value = event.target.value;
    setSelectedValue(value);
  
    try {
      const data = await getPlanById(planId, value, null);
      updatePlanData(data);
    } catch (error) {
      console.error('Error fetching plan details:', error);
    }
  };
  
  const isRelationship = async (event) => {
    const relationship = event.target.value;
    setSelectedRelationship(relationship);
  
    try {
      if (relationship !== "All") {
        const data = await getPlanById(planId, null, relationship);
        updatePlanData(data);
      } else {
        const data = await getPlanById(planId);
        updatePlanData(data);
      }
    } catch (error) {
      console.error('Error fetching plan details:', error);
    }
  };

  const handleViewDetailClick = ( id ) => {
    // alert(id)
    navigate(`/admin/viewPlanDetail/${id}`);
  };
  
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  
  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * pageSize + 1;
  const endIndex = Math.min(startIndex + pageSize - 1, totalCount);
  
  // Calculate total pages
  useEffect(() => {
    setTotalPages(Math.ceil(totalCount / pageSize));
  }, [totalCount, pageSize]);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
     <div className='plan-list'>
        <div className='container'>
          <div className='row justify-content-between'>
          
          

            <div className='col-md-12'>
              <h1 className='my-3 text-white'>Plan Information</h1>
            </div>
            <div className='col-md-auto ms-5'>
                <button 
                  className="btn btn-primary rounded-2 text-white px-5 mt-2" 
                  onClick={handleBackClick}
                  >
                    Back to Summary
                  </button>
            </div>
             <div className='col-md-6'>
              {/* <div className='d-md-flex align-items-end'>
              <div className="w-100">
                 <label htmlFor="exampleFormControlInput1" className="form-label text-white">{getStatusLabel(status)} Plan</label> 
                 <input type="text" className="form-control rounded-1 text-white inp px-3 mb-0 w-100" disabled value={getStatusLabel(status)} Plan /> 
              </div>
               <button className="btn btn-secondery rounded-2 text-white px-3 mt-2 ms-3 whiteSpace"
              >
              <i className="far fa-check-circle"></i> Approved &nbsp;&nbsp; {approvedCount} of {totalCount}
              </button> 
              </div> */}
            </div> 
            <div className='col-md-2'>
            <div className="w-auto">
                {/* <button className="btn btn-secondery rounded-2 text-white px-3 mt-2 ms-3 whiteSpace" 
                onClick={() => handleAddClick()}>Add a New Contact</button> */}
              </div>
            </div>
          
            {/* <div className='col-md-2'>
            <div className="w-auto">
                <label htmlFor="exampleFormControlInput1" className="form-label text-white">Sort By</label>
                <select className="form-select rounded-1 inp px-3 mb-0 w-100 selectCustom">
                  <option selected>Last Name</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div> */}
            <div className='col-md-12'>
            <div className="table-responsive">
                    <table className="table custom-table table-bordered mt-4 table-hover">
                      <thead>
                        <tr className='text-center'>
                        <th>No.</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Init</th>
                        <th>Email</th>
                        <th>Relationship Category</th>
                        {/* <th>Relationship Type</th>
                        <th>Parent</th> */}
                          <th>Contact Status</th>
                          <th>Verified</th>
                          <th>Actions</th>
                        {/* <th>Status</th>
                        <th>Actions</th> */}
                        </tr>
                      </thead>
                      <tbody>
                      {plan && plan.map((item, index) => (
                          <tr key={index} className='text-center'>
                            <td>{startIndex + index}</td>
                            <td>{item["First Name"] !== undefined ? item["First Name"] : '-'}</td>
                            <td>{item["Last Name"] !== undefined ? item["Last Name"] : '-'}</td>
                            <td>{item["M"] !== undefined ? item["M"] : '-'}</td>
                            <td>{item["Email"] !== undefined ? item["Email"] : '-'}</td>
                            <td>{item["Relationship Type"] !== undefined ? item["Relationship Type"] : '-'}</td>
                            {/* <td>{item["Relationship Order"] !== undefined ? item["Relationship Order"] : '-'}</td>
                            <td>{item["Parent"] !== undefined ? item["Parent"] : '-'}</td> */}
                            <td>
                            <span>
                                    <button 
                                    aria-disabled
                                    className="badge tbl-btn rounded-0  bg-verified w-100">
                                       {item["contactStatus"] ? (
                                        <>
                                          <img src='../images/dot.png' className='me-1' alt=''/> { item["contactStatus"] }
                                        </>
                                        ) : (
                                          '-'
                                        )}
                                    </button>
                              </span>
                            </td>
                            <td>
                            {item["Is Approved"] ? (
                                  <span>
                                    <button 
                                    // onClick={() => handleClick(item._id)}  
                                    className="badge tbl-btn rounded-0  bg-verified"
                                    disabled={["Deceased", "Estranged", "Remove"].includes(item["contactStatus"])}
                                    >
                                     Verified <img src='../../images/varify-icon.png' width={15} className='ms-1' alt=''/>
                                    </button>
                                  </span>
                                ) : (
                                  <span>
                                    <button 
                                    // onClick={() => handleClick(item._id)}  
                                    className="badge tbl-btn  rounded-0 bg-danger"
                                    disabled={["Deceased", "Estranged", "Remove"].includes(item["contactStatus"])}
                                    >
                                     Begin Validation <img src='../../images/varify-icon.png' width={15} className='ms-1' alt=''/>
                                    </button>
                                  </span>
                                )}
                    {/* {item["Is Approved"] ? (
                            <span>
                              <button  className="badge tbl-btn rounded-0  bg-verified">
                                Verified
                              </button>
                            </span>
                          ) : (
                            <span>
                              <button  className="badge tbl-btn rounded-0  bg-verified">
                                Not Verified
                              </button>
                            </span>
                          )} */}
                    </td>
                   <td>
                     <div className="d-flex">
                       <span>

                        <button onClick={() => handleViewDetailClick(`${item["_id"]}`)} className="badge tbl-btn rounded-0 bg-danger">
                           View
                         </button> 

                       </span>
                     </div>
                   </td>

                          </tr>
                        ))}

                      </tbody>
                    </table>
                  </div>
                  
                  
                  <div className='d-flex justify-content-between pagination mb-3 align-items-center'>
                  <div>
                      <span className='px-3 py-2'>Showing data {startIndex} to {endIndex} of { totalCount } entries</span>
                  </div>
                  <div className="">
                  <span className='px-3 py-2 d-inline-block'>
                <button className='badge bg-secondry' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                <button className='badge bg-danger ms-2' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                </span>
              </div>
                  </div>
                  
                

            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default AdminViewPlan;
