import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import Login from './pages/User/Login/Login';
import PlanList from './pages/User/PlanList/PlanList';
import EditPlan from './pages/User/EditPlan/EditPlan';
import AdminViewPlan from './pages/Admin/AdminViewPlan/AdminViewPlan';
import LoginAdmin from './pages/Admin/LoginAdmin/LoginAdmin';
import AdminPlanDetail from './pages/Admin/AdminPlanDetail/AdminPlanDetail';
import AdminPlan from './pages/Admin/AdminPlan/AdminPlan';
import AddPlan from './pages/User/AddPlan/AddPlan';
import PrivateRouteAdmin from './utilities/Authorize/PrivateRouteAdmin';
import PrivateRouteUser from './utilities/Authorize/PrivateRouteUser';
import HomePage from './pages/HomePage/HomePage';
import Approve from './pages/Admin/Plan/Approve/Approve';
import NonApprove from './pages/Admin/Plan/NonApprove/NonApprove';
import AllPlan from './pages/Admin/Plan/AllPlan/AllPlan';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {

  const location = useLocation();
  const pathname = location.pathname;

  // Check if the pathname matches '/admin/login' or '/'
  const isHeaderVisible = !(pathname === '/admin/login' || pathname === '/user/login' || pathname === '/');

  return (

    // <div className='content h-100'>
    <div className='content'>
      {isHeaderVisible && <Header />}
      <Routes>

        <Route path="/" element={< HomePage />} />
        {/* <Route path="/" element={< Login />} /> */}
        <Route path="/user/login" element={< Login />} />
        <Route path="/admin/login" element={< LoginAdmin />} />

        <Route path="/user" element={<PrivateRouteUser />} >
          <Route path="planList" element={<PlanList />} />
          <Route path="editPlan/:id" element={<EditPlan />} />
          <Route path="addPlan/:id" element={<AddPlan />} />
        </Route>

        <Route path="/admin" element={<PrivateRouteAdmin />} >
          <Route path="viewPlan/:planId" element={<AdminViewPlan />} />
          <Route path="plan/approve" element={<Approve />} />
          <Route path="plan/non-approve" element={<NonApprove />} />
          <Route path="plan/all" element={<AllPlan />} />
          <Route path="plan" element={<AdminPlan />} />
          <Route path="viewPlanDetail/:planId" element={<AdminPlanDetail />} />
        </Route>

      </Routes>
    </div>

  )
}

export default App;
