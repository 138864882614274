import React, { useEffect, useState } from 'react';
import { getApprovePlan } from '../../../../services/adminService';
import { useNavigate } from 'react-router-dom';

const Approve = () => {
    const navigate = useNavigate();
    const [plan, setPlan] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [searchPlanName, setSearchPlanName] = useState('');

    const limit = 11;

    useEffect(() => {
        fetchPlanList();
    }, [currentPage,searchPlanName]);

    const fetchPlanList = async () => {
        try {
            const planListData = await getApprovePlan(currentPage, limit, searchPlanName);
            setPlan(planListData.planList);
            setTotalRecord(planListData.totalCount);
            setTotalPages(Math.ceil(planListData.totalCount / limit));
            setIsDataFetched(true);
        } catch (error) {
            console.error('Error fetching planList:', error);
        }
    };

    const handleClick = (planId) => {
        navigate(`/admin/viewPlan/${planId}`);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleSearchChange = (e) => {
        setSearchPlanName(e.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    const startIndex = (currentPage - 1) * limit + 1;
    const endIndex = Math.min(startIndex + limit - 1, totalRecord);

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <div className='plan-list'>
            <div className='container'>
                <div className='row justify-content-between'>
                    <div className='col-md-12'>
                        <div className='row align-items-end'>
                            <div className='col-md'>
                               <h1 className='my-3 text-white'>All Approve Plan</h1>
                                <div className='col-md-auto ms-5'>
                                    <button
                                        className="btn btn-primary rounded-2 text-white px-5 mt-2"
                                        onClick={handleBackClick}
                                    >
                                        Back to Summary
                                    </button>

                                    <input 
                                        type='text' 
                                        placeholder='Search by plan name' 
                                        value={searchPlanName} 
                                        onChange={handleSearchChange} 
                                        className="searchButton rounded-2 px-5 mt-2"  
                                    /> 

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className="table-responsive">
                            <table className="table custom-table table-bordered mt-4 table-hover">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Plan Name</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {plan.map((item, index) => (
                                        <tr key={index}>
                                            <td>{startIndex + index}</td>
                                            <td>{item.planName}</td>
                                            <td>
                                                <button onClick={() => handleClick(item.planId)} className="badge tbl-btn rounded-0 bg-primary">
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='d-flex justify-content-between pagination mb-3 align-items-center'>
                            <div>
                                <span className='px-3 py-2'>
                                    Showing data {startIndex} to {endIndex} of {totalRecord} entries
                                </span>
                            </div>
                            <div className="">
                                <span className='px-3 py-2 d-inline-block'>
                                    <button className='badge bg-secondary' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                        Previous
                                    </button>
                                    <button className='badge bg-danger ms-2' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                        Next
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Approve;
