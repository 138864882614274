import React, { useEffect, useState } from 'react';
import { getPlanList, getPlanListV1, deletePlanById } from '../../../services/planListService';
import './PlanList.css';
import { useNavigate } from 'react-router-dom';
import Constants from '../../../utilities/constants'; 
import ConfirmationDialog from '../../../components/Modal/ConfirmationDialog';
import Modal from 'react-modal';

const PlanList = () => {
  const baseURL = Constants.BASE_URL + '/pdf-download';
  const [plan, setPlan] = useState([]);
  const [planName, setPlanName] = useState("");
  const [planId, setPlanId] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isError, setIsError] = useState(false); 
  const [idToDelete, setIdToDelete] = useState(null);

  useEffect(() => {
    if (!isDataFetched) {
    fetchClient(currentPage);
  }
  }, [currentPage, isDataFetched]);

  const fetchClient = async ( page ) => {
    try {

      // const planListData = await getPlanList(page);
      const planListData = await getPlanListV1(page);
      setPlan(planListData.data.planList);
      setTotalCount(planListData.data.totalCount);
      setApprovedCount(planListData.data.approvedCount);
      setIsDataFetched(true);
      setTotalPages(planListData.data.totalPages);
      setCurrentPage(page);
      setPlanName(planListData.data.planList[0]["Plan Name"]);
      setPlanId(planListData.data.planList[0]["_id"]);
      console.log(planListData.data.planList
        , "planLists");

    } catch (error) {
      console.error('Error fetching planList:', error);
    }
  };

  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/user/editPlan/${id}`);
  };

  const handleConfirmDelete = async () => {
    if (idToDelete) {
        await deletePlanById(idToDelete); 
        // await fetchClient();
        await fetchClient(currentPage); // Pass the current page to ensure correct data fetch
        setIdToDelete(null);
    }
    setIsDialogVisible(false);
};

  const deletePlan = async (id) => {
    try {
    
      if(id){
        setDialogTitle('Error');
        setDialogMessage('Are you sure you want to delete this plan?');
        setIsError(true); 
        setIsDialogVisible(true);
        setIdToDelete( id )
        return;
      }
      
    } catch (error) {
      console.error('Error Deleting plan:', error);
      throw error;
    }
  };

  const handleAddClick = async () => {
    try {

      navigate(`/user/addPlan/${planId}`);

    } catch (error) {
      // console.error('Error Deleting plan:', error);
      // throw error;
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      fetchClient(newPage);
    }
  };

    // Calculate the start index for the current page
    const startIndex = (currentPage - 1) * 10 + 1;
    const endIndex = Math.min(startIndex + 9, totalCount);
    const userToken = localStorage.getItem('userToken'); 
    const openPdfDownload = async () => {
      // const url = `${baseURL}?plan=${planName}`;
      // console.log(url,'url')
      // window.open(url, '_blank', 'noopener,noreferrer');

      try {
        const response = await fetch(`${baseURL}?plan=${planName}`, {
          method: 'GET', // or 'POST' if that's how your endpoint is designed
          headers: {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json' // Add any other headers your API requires
          }
        });
        
        const data = await response.json();
        // console.log(data);
        if (data.redirect_url) {
          window.location.href = data.redirect_url;
        } else {
          alert('Error generating signing URL');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Error generating signing URL');
      }
    };


    const resetAgreement = async( ) => {
      try{
        const response = await fetch(`${baseURL}/resetAgreement?plan=${planName}`, {
          method: 'GET', // or 'POST' if that's how your endpoint is designed
          headers: {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json' // Add any other headers your API requires
          }
        });
        // console.log(response);
        if(response.status == 400)
          alert('No agreement signed.');
        else
        alert('success');
      } catch(error){
        alert('No agreement signed.');
      }
    }

  return (
    <>
      <div className='plan-list'>
        <div className='container'>
          <div className='row justify-content-between align-items-end'>
            <div className='col-md-12'>
              <h1 className='my-5 text-white'>Contact Information Update</h1>
            </div>
            <div className='col-md-6'>
              <div className='d-md-flex align-items-end'>
              <div className="w-100">
                <label htmlFor="exampleFormControlInput1" className="form-label text-white">Name</label>
                <input type="text" className="form-control rounded-1 text-white inp px-3 mb-0 w-100" disabled value={planName} />
              </div>
              <button className="btn btn-secondery rounded-2 text-white px-3 mt-2 ms-3 whiteSpace"
              >
              <i className="far fa-check-circle"></i> Approved &nbsp;&nbsp; {approvedCount} of {totalCount}
              </button>
              </div>  
            </div>
            {/* <div className="col-md-6 buttonWrapper">
            <button 
      className="btn btn-secondery rounded-2 text-white px-3 mt-2 ms-3 whiteSpace"
      onClick={openPdfDownload}
      >
           Send DocuSign
      </button>
      {<button 
      className="btn btn-secondery rounded-2 text-white px-3 mt-2 ms-3 whiteSpace" onClick={resetAgreement}>
           Reset Agreement
      </button>}
            </div> */}
            {/* <div className='col-md-auto'>
            <div className="w-auto">
                <button className="btn btn-primary rounded-2 text-white px-5 mt-2 whiteSpace" 
                onClick={() => handleAddClick()}>Add a New Contact</button>
              </div>
            </div> */}
            {/* <div className='col-md-2'>
            <div className="w-auto">
                <label htmlFor="exampleFormControlInput1" className="form-label text-white">Sort By</label>
                <select className="form-select rounded-1 inp px-3 mb-0 w-100 selectCustom">
                  <option selected>Last Name</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div> */}
            <div className='col-md-12'>
            <div className="table-responsive">
                    <table className="table custom-table table-bordered mt-4 table-hover">
                      <thead>
                        <tr className='text-center'>
                          <th>No.</th>
                          <th>First Name</th>
                          <th>Initial</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Relationship Category</th>
                          {/* <th>Relationship Type</th>
                          <th>Parent</th> */}
                          <th>Contact Status</th>
                          <th>Verified</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {plan.map((item, index) => (
                          <tr key={index} className='text-center'>
                            <td>{startIndex + index}</td>
                            <td className='capitalize'>
                              {item["First Name"] !== undefined && item["First Name"] !== "" ? item["First Name"] : 
                                    (item["Charity Name"] !== undefined && item["Charity Name"] !== "" ? '' : '-')}
                              {item["Charity Name"] !== undefined && item["Charity Name"] !== "" ? ' ' + item["Charity Name"] : ''}

                            </td>
                            <td className='capitalize'>{item["M"] !== undefined && item["M"] !== "" ? item["M"] : '-'}</td>
                            <td className='capitalize'>{item["Last Name"] !== undefined && item["Last Name"] !== "" ? item["Last Name"] : '-'}</td>
                            <td>{item["Email"] !== undefined && item["Email"] !== "" ? item["Email"] : '-'}</td>
                            <td>{item["Relationship Type"] !== undefined && item["Relationship Type"] !== "" ? item["Relationship Type"] : '-'}</td>
                            {/* <td>{item["Relationship Order"] !== undefined ? item["Relationship Order"] : '-'}</td>
                            <td>{item["Parent"] !== undefined ? item["Parent"] : '-'}</td> */}
                            {/* <td>{`${item["Relationship Type"]}`}</td>
                            <td>{`${item["Relationship Order"]}`}</td> */}
                            {/* <td>{`${item["Is Approved"]}`}</td> */}
                            <td>
                              <span>
                                    <button 
                                    disabled
                                    className="badge tbl-btn rounded-0  bg-verified w-100">
                                       {item["contactStatus"] ? (
                                        <>
                                          <img src='../images/dot.png' className='me-1' alt=''/> { item["contactStatus"] }
                                        </>
                                        ) : (
                                          '-'
                                        )}
                                    </button>
                              </span>
                            </td>
                            <td>
                              <div className="d-flex gap-2">
                                {/* <span>

                                  <button onClick={() => handleClick(item._id)} className="badge tbl-btn rounded-0 bg-primary">
                                    Edit
                                  </button>

                                </span> */}
                                {/* <span><a href="" className="badge tbl-btn rounded-pill bg-danger">Not Approved</a></span>
                            <span><a href="" className="badge tbl-btn rounded-pill bg-success">Approved</a></span> */}
                                {item["Is Approved"] ? (
                                  <span>
                                    <button 
                                    onClick={() => handleClick(item._id)}  className="badge tbl-btn rounded-0  bg-verified"
                                    disabled={["Deceased", "Estranged", "Remove"].includes(item["contactStatus"])}
                                    >
                                     Verified 
                                     <img src='../../images/varify-icon.png' width={15} className='ms-1' alt=''/>
                                    </button>
                                  </span>
                                ) : (
                                  <span>
                                    <button onClick={() => handleClick(item._id)}  
                                    className="badge tbl-btn  rounded-0 bg-danger"
                                    disabled={["Deceased", "Estranged", "Remove"].includes(item["contactStatus"])}
                                    >
                                     Begin Validation 
                                     <img src='../../images/varify-icon.png' width={15} className='ms-1' alt=''/>
                                    </button>
                                  </span>
                                )}

                              </div>
                            </td>
                            <td>
                            <div className="d-flex gap-2">
                                <span>
                                  <button onClick={() => deletePlan(item._id)} className="badge tbl-btn rounded-0 bg-danger">
                                    Delete
                                  </button>
                                </span>
                            </div>

                            </td>

                          </tr>
                        ))}

                        <tr>
                          <td colSpan="11" className="text-center">  
                          <button className="btn btn-primary rounded-2 text-white px-5 mt-2 whiteSpace" 
                        onClick={() => handleAddClick()}>Add a New Contact</button></td>
                        </tr>

                      </tbody>
                      
                    </table>

                  </div>

                  <div className='d-flex justify-content-between pagination mb-3 align-items-center'>
                    <div>
                      <span className='px-3 py-2'>{startIndex} to {endIndex} of {totalCount} entries</span>
                    </div>
                    <div className="">
                      <span className='px-3 py-2 d-inline-block'>
                        <button className='badge bg-secondry' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                        <button className='badge bg-danger ms-2' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                      </span>
                    </div>
                  </div>
                
            </div>
          </div>
        </div>
      </div>

      <Modal className="customModalContent" isOpen={isDialogVisible} onRequestClose={() => setIsDialogVisible(false)}>
        <ConfirmationDialog 
        title={dialogTitle} 
        message={dialogMessage} 
        isError={isError} 
        idToDelete={idToDelete}
        onConfirm={handleConfirmDelete} 
        onClose={() => setIsDialogVisible(false)} />
      </Modal>

    </>
  );

};

export default PlanList;
