import axios from 'axios';
import Constants from '../utilities/constants'; 
import { getToken } from './tokenService'; 

const baseURL = Constants.BASE_URL + '/admin/plan';

export const createClient = async (clientData) => {
  try {
    const response = await axios.post(baseURL, clientData);
    return response.data;
  } catch (error) {
    console.error('Error creating client:', error);
    throw error;
  }
};

export const getGroupByList = async ( ) => {
  try {
    // const token = getToken();
    const response = await axios.get(`${baseURL}/groupBy`,{
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
   
    return response.data;

  } catch (error) {
    console.error('Error fetching client:', error);
    throw error;
  }
};

export const getPlanByIdSingleRecord = async ( id ) => {
  try {
    // const token = getToken();
    const response = await axios.get(`${baseURL}/${id}`,{
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching client:', error);
    throw error;
  }
};

// export const getPlanById = async ( planId, isApproved = null, isRelationship = null ) => {
//   try {
//     let url = `${baseURL}/listByPlanName/${planId}`;
//     if (isApproved !== null) {
//       url += `?Is Approved=${isApproved}`;
//     }

//     if (isRelationship !== null) {
//       url += `?Relationship Order=${isRelationship}`;
//     }

//     const response = await axios.get(url);
//     return response.data.data;
//   } catch (error) {
//     console.error('Error fetching client:', error);
//     throw error;
//   }
// };

export const getPlanById = async (planId, page = 1, limit = 10, isApproved = null, isRelationship = null) => {
  try {
    let url = `${baseURL}/listByPlanName/v1/${planId}?page=${page}&limit=${limit}`;
    // let url = `${baseURL}/listByPlanName/${planId}?page=${page}&limit=${limit}`;

    if (isApproved !== null) {
      url += `&Is Approved=${isApproved}`;
    }

    if (isRelationship !== null) {
      url += `&Relationship Order=${isRelationship}`;
    }

    const response = await axios.get(url);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching client:', error);
    throw error;
  }
};


export const updatePlanById = async ( planId, planData ) => {
  try {
    const token = getToken();
    const response = await axios.put(`${baseURL}/${planId}`, planData,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(response.data.message); 
  } catch (error) {
    console.error('Error updating plan:', error);
  }
};

export const getApproveNonApproveTotal = async (  ) => {
  try {
    // const token = getToken();
    const response = await axios.get(`${baseURL}/approveNonApproveTotal`,{
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching client:', error);
    throw error;
  }
};

export const getApprovePlan = async ( page = 1, limit = 11, searchPlanName = '' ) => {
  try {
    
      const url = `${baseURL}/groupByStautsApprove?currentPage=${page}&limit=${limit}&searchPlanName=${encodeURIComponent(searchPlanName)}`;
      const response = await axios.get(url);
      return response.data.data;
    
  } catch (error) {
    console.error('Error fetching client:', error);
    throw error;
  }
};

export const getNonApprovePlan = async ( page = 1, limit = 11, searchPlanName = '' ) => {
  try {
    
      const url = `${baseURL}/groupByStautsNonApprove?currentPage=${page}&limit=${limit}&searchPlanName=${encodeURIComponent(searchPlanName)}`;
      const response = await axios.get(url);
      return response.data.data;
    
  } catch (error) {
    console.error('Error fetching client:', error);
    throw error;
  }
};

export const getAllApprovePlan = async ( page = 1, limit = 11, searchPlanName = '' ) => {
  try {
    
      const url = `${baseURL}/groupByStautsAllPlan?currentPage=${page}&limit=${limit}&searchPlanName=${encodeURIComponent(searchPlanName)}`;
      const response = await axios.get(url);
      return response.data.data;
    
  } catch (error) {
    console.error('Error fetching client:', error);
    throw error;
  }
};