import React from 'react';
import './MessageDialog.css';

const MessageDialog = ({ message, isError, onClose, title, onOk }) => {
    
    const handleOk = () => {
        if (!isError && onOk) {
            onOk();
        } else {
            onClose();
        }
    };

    return (
        
        <div className="modal-dialog modal-dialog-centered loginHelp">
        <div className="modal-content px-5 py-2">
            <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
                <button type="button" className="btn-close" onClick={onClose}>
                </button>
            </div>
            <div className="modal-body pt-0 pb-5 px-4 text-center">
            <img src="../../images/loginhelp.svg" alt="..." />
                <h2 className='mb-3 text-white my-4 text-success'>
                {message}
                    {/* {isError ? 'Error' : 'Success'} 
                    Alert */}
                </h2>
                <div className="col-md-12">
                    <div className="d-flex align-items-center">
                        <p className="mb-0 text-white">
                            {/* {message} */}
                            {/* { isError } */}
                        </p>
                    </div>
                </div>
                <div className='gap-4'>        
                    <button 
                    type="button" 
                    className={`btn px-5 btnSize rounded-3 ${isError ? 'btn-primary' : 'btn-success'}`}
                    onClick={handleOk}
                    >Ok</button>
                </div>

            </div>
        </div> 
    </div>

    );
};

export default MessageDialog;

