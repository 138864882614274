import './Profile.css';
import { Link } from 'react-router-dom';

function Profile( ) {

  function handlePhoneClick() {
    window.open('tel:+6173488277');
  }

  function handleEmailClick() {
    window.location.href = "mailto:pshumaker@burnslev.com";
  }

  return (
    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
    <div className='rightSec p-md-5 p-3'>
      <div className='row margin_50px'>
        <div className='col-lg-3 text-white text-center'>
          <img src='./images/elder-law.jpg' className='w-100 mb-3' alt='' />
          <h6 className='mb-0 fs-20'>Elder Law, Estate Planning, Estate Administration</h6>
        </div>
        <div className='col-lg text-white ps-md-5'>
          <p className='textAlign'>My practice has moved to Conn Kavanaugh Rosenthal Peisch & Ford, LLP ("Conn Kavanaugh LLP") to provide you with improved service and a full range of legal expertise. This partnership strengthens my ability to offer you and your family the very best legal assistance.
          </p>

          <p className='textAlign'>I appreciate the opportunity to assist you with your legal work and look forward to continuing our relationship to ensure you reach your planning goals.</p>

          <p className='textAlign'>To begin the transition, please update your contact information by clicking the button below. Once your contacts are updated, you will receive a letter by Docusign to authorize me to bring your records to Conn Kavanaugh  LLP.</p>
            <div className='col-md-12 pb-0'>
        <div className='row'>
          <div className='col-md'><span className='w-100 d-block text-white fs-17'><img className='me-3' src='./images/calender-icon.png' alt=''/> Licensed for 23 years</span></div>
          <div className='col-md'><span className='w-100 d-block fs-17'><img className='me-3' src='./images/phone-icon.png' alt=''/>
          <Link onClick={handlePhoneClick} className="text-decoration-none text-white">(617) 348-8277</Link>
          </span></div>
          <div className='col-md'><Link to="#" className='text-decoration-none text-white fs-17 d-md-flex' onClick={handleEmailClick}>
            <img className='me-3' src='./images/email-icon.png' alt=''/> pshumaker@connkavanaugh.com </Link></div>
          </div>
        </div>     
          {/* <div className='w-100 text-center d-flex gap-3 justify-content-center mt-4 align-items-center'>
            <Link to="#" className='text-white'>Next Steps </Link>
            <button type="button" className="btn btn-primary">Current Clients</button>
            <button type="button" className="btn btn-primary">New Clients</button>
          </div> */}

          <div className='w-100 text-center d-flex gap-3 justify-content-center mt-4 align-items-center'>
            
            {/* <Link to="admin/login" className="btn btn-primary">Admin Login</Link> */}
          </div>

        </div>
        <div className="col-md-12 my-3"><hr className='broderColr'></hr></div>     
        <div className='col-md-8'>
          <p className="text-white mb-0 hrPara">
            The first step to transition your records to Conn Kavanaugh LLP is to review 
            <br/>and update your contact information. Click the button to begin this process.
          </p>
        </div>
        
        <div className='col-md-4 text-end' id="homepage-button">
          <Link to="user/login" className="btn btn-primary mt-3">
            Update Contact Information
          </Link>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Profile;