import React from 'react';
import { Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Constants from '../../utilities/constants';

const PrivateRouteUser = () => {
    const { userloggedIn } = Constants.getTokens();

    if (userloggedIn) {
        return <Outlet />
    } else {
        return <Navigate to={"/user/login"} />
    }

};

export default PrivateRouteUser;